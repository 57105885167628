/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, Container, DialogActions, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemeButton } from '../../components/button';
import DataTable from '../../components/table';
import { Types } from '../../types';
import StatusIndicator from '../../components/status';
import { addSector, editSector, getSectors } from './sectorsSlice';
import ModalComponent from '../../components/ModalComponent';
import FormInput from '../../components/formComponents/FormInput';
import CustomSelect from '../../components/select';
import { getIndustries } from '../industry/industrySlice';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import axiosInstance from '../../utils/axiosInstance';
import { addSectorsSchema } from '../../utils/schema';

const SectorsListListPage = () => {
  const headCells = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      minWidth: 200,
      titleCase: true,
    },
    {
      id: 'industryName',
      label: 'Industry',
      align: 'left',
      minWidth: 200,
      titleCase: true,
    },
    {
      id: 'status',
      label: 'Status',
      renderElement: (cell) => <StatusIndicator cell={cell} />,
      width: '15%',
    },
  ];
  const [rowToEdit, setRowToEdit] = useState('');
  const form = useForm({
    defaultValues: {
      name: '',
      industryID: '',
    },
    resolver: yupResolver(addSectorsSchema),
  });
  const { register, handleSubmit, formState, reset, setValue, control } = form;
  const { errors } = formState;

  const [isModalopen, setisModalopen] = useState(false);
  const { sectors, isLoading, isAdding } = useSelector((state) => state.sectors);
  const { industries } = useSelector((state) => state.industry);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSectors());
    dispatch(getIndustries());
  }, [dispatch]);
  const industryOptions = useMemo(
    () =>
      industries
        .filter((industry) => industry.status === 'active')
        .map((industry) => ({ label: industry.name, value: industry._id })),
    [industries]
  );
  const regularSectors = useMemo(
    () =>
      !isEmpty(sectors) &&
      sectors.reduce(
        (acc, sector) => {
          const fullSector = {
            ...sector,
            industryName: (industries?.length && industries.find((industry) => industry?._id === sector?.industryID)?.name) || '',
          };
          if (sector?.status === 'active') {
            acc.active.push(fullSector);
          } else if (sector?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(fullSector);
          }
          acc.default.push(fullSector);
          return acc;
        },
        { active: [], archive: [], default: [] }
      ),
    [industries, sectors]
  );
  useEffect(() => {
    if (regularSectors) {
      if (Status === DEFAULT) {
        setUserRows(regularSectors?.default);
      } else if (Status === ARCHIVED) {
        setUserRows(regularSectors?.archive);
      } else if (Status === ACTIVE) {
        setUserRows(regularSectors?.active);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularSectors]);

  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setUserRows(regularSectors.default);
      } else if (value === ACTIVE) {
        setUserRows(regularSectors.active);
      } else if (value === ARCHIVED) {
        setUserRows(regularSectors.archive);
      }
    }
  };
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [Status, setStatus] = useState(ACTIVE);
  const [userRows, setUserRows] = useState([]);
  const getStatusPayload = (selectedIds, status) => {
    const fetchUrl = 'sectors/update/status';
    const payload = {
      sectorIDs: selectedIds,
      status,
    };
    return {
      fetchUrl,
      payload,
    };
  };
  const getRemovePayload = (selectedIds) => {
    const deleteUrl = `sectors/delete/${selectedIds[0]}`;
    const deletePayload = {
      sectorIDs: selectedIds,
    };
    return {
      deleteUrl,
      deletePayload,
    };
  };
  const fetchData = async () => dispatch(getSectors());
  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search Sector',
    getStatusPayload,
    fetchData,
    getRemovePayload,
  };

  const onSubmit = (values) => {
    if (values) {
      if (rowToEdit) {
        dispatch(editSector({ ...values, _id: rowToEdit })).then((response) => {
          if (response.payload?.success) {
            closeModal();
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message' || 'User Updated Successfully'),
              })
            );
            dispatch(getSectors());
          } else {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        });
      } else {
        dispatch(addSector(values))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'User Added Successfully'),
                })
              );
              dispatch(getSectors());
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };

  const openModal = () => setisModalopen(true);
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    // setisModalopen(false);
    reset();
    reset({ industryID: '' });
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };

  const onEditClick = async (event, row) => {
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      openModal();
      try {
        const response = await axiosInstance({
          url: `sectors/get/${row?._id}`,
          method: 'GET',
        });
        if (get(response, 'data.data', '')) {
          setValue('name', get(response, 'data.data.name', ''));
          setValue('industryID', get(response, 'data.data.industryID', ''));
        }
      } catch (error) {
        console.log('error -> getUsersById');
      }
    } else {
      console.warn('No Row Id found');
    }
  };

  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'sectors/update/status',
          method: 'PATCH',
          data: { sectorIDs: [row?._id], status },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Sector`,
            })
          );
          dispatch(getSectors());
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };

  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `sectors/delete/${row?._id}`,
          method: 'DELETE',
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getSectors());
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };

  return (
    <Container maxWidth="xxl">
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '500px !important',
          },
        }}
        title={rowToEdit ? 'Edit Sector' : 'Create Sector'}
        open={isModalopen}
        onClose={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            <FormInput
              label="Sector Name"
              placeholder="Enter Name"
              InputProps={{
                ...register('name'),
              }}
              error={!!errors?.name}
              helperText={errors?.name && errors?.name?.message}
            />
            <>
              <label id="customer-select" style={{ fontSize: '0.8600rem' }}>
                Industry
              </label>
              <CustomSelect
                name="industryID"
                // InputProps={{ ...register('customerID') }}
                control={control}
                options={industryOptions}
                label="Select a Industry"
                // value={watch('customerID')}
                error={!!errors?.industryID}
                helperText={errors?.industryID && errors?.industryID?.message}
                // placeholder=Chooseexpiration date"
              />
            </>
          </Box>
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={isAdding} type="submit" variant="contained">
              {rowToEdit ? 'Save Changes' : 'Create Sector'}
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
        <Typography variant="h4">Sectors</Typography>
        <ThemeButton onClick={openModal} variant="contained" color="success" startIcon={<AddCircleIcon fontSize="small" />}>
          Create Sector
        </ThemeButton>
      </Box>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        onEditClick={onEditClick}
        onRowStatusChange={onRowStatusChange}
        defaultRowsPerPage={10}
        maxHeight={653}
        filterKeys={['name']}
        customToolbar={Types.toolbarTypes.SUPER_USER}
        toolbarProps={toolbarProps}
        rows={userRows}
        stickyHeader
        onRowDelete={onDelete}
      />
    </Container>
  );
};

export default SectorsListListPage;
