/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { Box, Card, CircularProgress, Container, FormLabel, Grid, IconButton, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getStudyColor } from '../../components/StudiesComponent';
import { ThemeButton } from '../../components/button';
import CheckboxGroup from '../../components/checkbox/CheckboxGroup';
import ThemeCheckbox from '../../components/checkbox/themeChekbox';
import FormInput from '../../components/formComponents/FormInput';
import FormDatePicker from '../../components/formComponents/formDatePicker';
import CustomSelect from '../../components/select';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import ThemeSwitch from '../../components/switch';
import { APP_ROUTER_BASE_URL } from '../../utils';
import axiosInstance from '../../utils/axiosInstance';
import { addProjectSchema } from '../../utils/schema';
import { getCustomers } from '../customer/customerSlice';
import { addProject, getSingleProject, updateProject } from './projectSlice';
import Datetime from '../../components/Datetime';
import DigitalClock from '../../components/DigitalClock';

const studyAppConfig = { label: 'label', value: 'value' };
const defaultStudyOptions = [
  { label: 'Efficiency Study', value: 1, color: '#f59d0f' },
  { label: 'Role Study', value: 2, color: '#784fc2' },
  { label: 'Activity Study', value: 3, color: '#0ba0ef' },
  { label: 'Predetermined Study', value: 4, color: '#9CC13B' },
];
const elementAppOptions = [
  { label: 'Efficiency Study', value: 1 },
  { label: 'Role Study', value: 2 },
  { label: 'Activity Study', value: 3 },
];
// const elementAppOptions = [
//   { label: 'Efficiency Study', value: 1 },
//   { label: 'Role Study', value: 2 },
//   { label: 'Activity Study', value: 3 },
// ];

const AddProjectPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const isEditing = id !== undefined;
  const [managerOptions, setManagerOptions] = useState([]);
  const [shouldShowRoundTime, setShouldShowRoundTime] = useState(false);
  const [firstRendered, setFirstRendered] = useState(false);
  const [disabledStudyOptions, setDisabledStudyOptions] = useState([]);
  const [studyAppOptions, setStudyAppOptions] = useState([
    { label: 'Efficiency Study', value: 1, color: '#f59d0f' },
    { label: 'Role Study', value: 2, color: '#784fc2' },
    { label: 'Activity Study', value: 3, color: '#0ba0ef' },
    // { label: 'Predetermined Study', value: 4, color: '#9CC13B' },
  ]);
  const [isManagerFetched, setManagerFetched] = useState(false);
  const handleBack = () => {
    navigate(-1); // This will take you back to the previous page
  };
  const { customers, isLoading } = useSelector((state) => state.customer);
  const { singleProjectData, isSingleProjectLoading, isAdding } = useSelector((state) => state.project);
  const customerOptions = useMemo(() => {
    if (customers && customers?.length >= 0) {
      return customers?.map((customer) => ({ label: customer?.name, value: customer?._id }));
    }
    return [];
  }, [customers]);

  useEffect(() => {
    dispatch(getCustomers({ status: 'active' }));
  }, [dispatch]);

  const form = useForm({
    defaultValues: {
      name: '',
      startDate: '',
      endDate: '',
      customerID: '',
      manager: '',
      studyTypes: [],
      promptTriggerTime: {
        minutes: 0,
        seconds: 0,
      },
      addSystemElement: true,
      // optional fields
      maxFrequency: null,
      rating: true,
      studyExport: false,
      projectStats: false,
      multipleCountsPerArea: false,
      multipleTrackingPerArea: false,
      exportInTimeFormat: false,
      roundTime: 10,
      rerateElement: false,
      allowRoundTimeEdit: false,
      level3: false,
      areaCreationDILO: false,
      areaCreationDOTS: false,
      areaCreationRAS: false,
      elementCreationDILO: false,
      elementCreationDOTS: false,
      elementCreationRAS: false,
      roleCreationDILO: false,
      roleCreationRAS: false,
    },
    resolver: yupResolver(addProjectSchema),
    mode: 'onChange',
  });
  const {
    register,
    control,
    watch,
    trigger,
    getFieldState,
    setValue,
    formState: { errors, isDirty },
    resetField,
    getValues,
    handleSubmit,
  } = form;
  useEffect(() => {
    if (isEditing && id) {
      dispatch(getSingleProject(id)).then((response) => {
        if (response.payload.success && response.payload.data) {
          //   {
          //     "promptTriggerTime": {
          //         "minutes": 0,
          //         "seconds": 0
          //     },
          //     "level3": false,
          //     "customerName": "Amazon Fresh"
          // }
          setValue('name', get(response, 'payload.data.name', ''));
          setValue('startDate', get(response, 'payload.data.startDate', ''));
          setValue('promptTriggerTime', get(response, 'payload.data.promptTriggerTime', { minutes: 0, seconds: 0 }));
          setValue('endDate', get(response, 'payload.data.endDate', ''));
          setValue('customerID', get(response, 'payload.data.customerID', ''));
          setValue('manager', get(response, 'payload.data.manager', ''));
          setValue('maxFrequency', get(response, 'payload.data.maxFrequency', null));
          setValue('projectStats', get(response, 'payload.data.projectStats', false));
          setValue('multipleCountsPerArea', get(response, 'payload.data.multipleCountsPerArea', false));
          setValue('multipleTrackingPerArea', get(response, 'payload.data.multipleTrackingPerArea', false));
          setValue('studyTypes', get(response, 'payload.data.studyTypes', []));
          setValue('addSystemElement', get(response, 'payload.data.addSystemElement', false));
          setValue('addSystemElement', get(response, 'payload.data.addSystemElement', false));
          setValue('areaCreationDILO', get(response, 'payload.data.areaCreationDILO', false));
          setValue('areaCreationDOTS', get(response, 'payload.data.areaCreationDOTS', false));
          setValue('areaCreationRAS', get(response, 'payload.data.areaCreationRAS', false));
          setValue('elementCreationDILO', get(response, 'payload.data.elementCreationDILO', false));
          setValue('elementCreationDOTS', get(response, 'payload.data.elementCreationDOTS', false));
          setValue('elementCreationRAS', get(response, 'payload.data.elementCreationRAS', false));
          setValue('roleCreationDILO', get(response, 'payload.data.roleCreationDILO', false));
          setValue('roleCreationRAS', get(response, 'payload.data.roleCreationRAS', false));
          setValue('studyExport', get(response, 'payload.data.studyExport', false));
          setValue('rating', get(response, 'payload.data.rating', false));
          setValue('rerateElement', get(response, 'payload.data.rerateElement', false));
          setValue('exportInTimeFormat', get(response, 'payload.data.exportInTimeFormat', false));
          setValue('allowRoundTimeEdit', get(response, 'payload.data.allowRoundTimeEdit', false));
          setValue('roundTime', get(response, 'payload.data.roundTime', 10) || 10);
          setValue('level3', get(response, 'payload.data.level3', false));
          setValue('roundTimeBoolean', get(response, 'payload.data.roundTimeBoolean', false));
          if (
            get(response, 'payload.data.studyTypes') &&
            response?.payload?.data?.studyTypes?.length > 0 &&
            response?.payload?.data?.studyTypes.includes(1)
          ) {
            if (!studyAppOptions.some((option) => option.value === 5)) {
              // studyAppOptions.push({ label: 'Set round time centrally', value: 5 });
              // setStudyAppOptions([...studyAppOptions, { label: 'Set round time centrally', value: 5 }]);
            }
          }
          // if (get(response, 'payload.data.roundTimeBoolean') && get(response, 'payload.data.studyTypes')) {
          //   const types = get(response, 'payload.data.studyTypes', []);
          //   setValue('studyTypes', [...types, 5]);
          // }
          if (get(response, 'payload.data.studyTypes') && response?.payload?.data?.studyTypes?.includes(4)) {
            setDisabledStudyOptions([1, 2, 3]);
          }
        }
      });
    }
  }, [isEditing, id, dispatch, setValue, studyAppOptions]);
  const customer = watch('customerID');
  const manager = watch('manager');
  const fetchManagerOptions = async (customerId) => {
    const response = await axiosInstance({
      url: 'users/get',
      method: 'POST',
      data: { role: 2, permission: 1, customerID: customerId },
    });
    const data = await response.data;
    if (data?.success) {
      const options = data?.data?.map((user) => ({ label: user?.name, value: user?._id }));
      setManagerOptions(options);
      setManagerFetched(true);
    }
  };
  const shouldRenderForm = useMemo(() => {
    if (!isEditing) {
      return true;
    }
    if (isEditing && singleProjectData) {
      return true;
    }
    return false;
  }, [isEditing, singleProjectData]);
  useEffect(() => {
    if (!customer) {
      // setValue('manager', '');
    } else {
      // setValue({ manager: '' });
      fetchManagerOptions(customer);
      // setValue('manager', '');
    }
  }, [customer, setValue]);
  useEffect(() => {
    if (manager && isManagerFetched && managerOptions?.length >= 0 && !managerOptions.some((user) => user?.value === manager)) {
      setValue('manager', '');
    }
  }, [isManagerFetched, manager, managerOptions, setValue]);
  const onSubmit = (values) => {
    if (isEditing && id) {
      dispatch(updateProject({ ...values, _id: id })).then((response) => {
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message' || 'User Updated Successfully'),
            })
          );
          // navigate(`${APP_ROUTER_BASE_URL}project`);
          handleBack();
        } else {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      });
    } else {
      dispatch(addProject(values)).then((response) => {
        const idToRederict = get(response, 'payload.data._id');
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message' || 'User Updated Successfully'),
            })
          );
          // navigate(`${APP_ROUTER_BASE_URL}project`);
          navigate(`${APP_ROUTER_BASE_URL}project/${idToRederict}`, { state: { isNewProject: true } });
        } else {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      });
    }
  };
  const studyTypes = watch('studyTypes');
  const rerateElement = watch('rerateElement');
  const roundTimeBoolean = watch('roundTimeBoolean');
  const roundTImeOptions = useMemo(() => Array.from({ length: 30 }, (_, index) => ({ label: index + 1, value: index + 1 })), []);
  useEffect(() => {
    if (!rerateElement) {
      setValue('promptTriggerTime', { minutes: 0, seconds: 0 });
    }
  }, [rerateElement, setValue]);
  // useEffect(() => {
  //   if ((studyTypes?.includes(2) || studyTypes?.includes(3)) && !rerateElement) {
  //     setValue('rerateElement', true);
  //     setValue('promptTriggerTime', { minutes: 2, seconds: 0 });
  //   }
  // }, [rerateElement, setValue, studyTypes]);

  // const startDate = watch('startDate');
  // const endDate = watch('endDate');
  const isPredetermined = useMemo(() => !!(studyTypes?.length && studyTypes.indexOf(4) !== -1), [studyTypes]);
  const isLevel3 = watch('level3');
  useEffect(() => {
    if (studyTypes?.length >= 0 && studyTypes.indexOf(4) === -1 && isLevel3) {
      setValue('level3', false);
    }
    if (!isEditing && !firstRendered && studyTypes?.indexOf(1) !== -1) {
      setValue('maxFrequency', 10);
      setFirstRendered(true);
    }
  }, [firstRendered, isEditing, isLevel3, setValue, studyTypes]);
  useEffect(() => {
    if (studyTypes.includes(1)) {
      // const optionIndex = studyAppOptions.findIndex((x) => x.value === 5);
      // if (optionIndex === -1) {
      //   setStudyAppOptions([...studyAppOptions, { label: 'Set round time centrally', value: 5 }]);
      // }
      setShouldShowRoundTime(true);
    } else {
      setShouldShowRoundTime(false);
      if (roundTimeBoolean) {
        setValue('roundTimeBoolean', false);
      }
    }
  }, [roundTimeBoolean, setValue, studyTypes]);

  const onValueChange = (onChange, value, item) => {
    const newArray = [...value];
    if (item === 1) {
      if (value.indexOf(1) !== -1) {
        // item exists in values
        // const index = studyAppOptions.findIndex((x) => x.value === item);
        const index = newArray.findIndex((x) => x === item);
        newArray.splice(index, 1);
        if (studyAppOptions?.some((option) => option.value === 5)) {
          // option 5 exists in options
          // const optionIndex = studyAppOptions.findIndex((x) => x.value === 5);
          // studyAppOptions.splice(optionIndex, 1);
          const filteredOptions = studyAppOptions.filter((option) => option.value !== 5);
          // setStudyAppOptions(filteredOptions);
          if (newArray.indexOf(5) !== -1) {
            // option 5 is selected in vlaues
            const fifthValueIndex = newArray.findIndex((x) => x === 5);
            newArray.splice(fifthValueIndex, 1);
          }
        }
        resetField('allowRoundTimeEdit');
        resetField('roundTime');
      } else {
        newArray.push(item);
        const newStudyOption = [...studyAppOptions, { label: 'Set round time centrally', value: 5 }];
        // setStudyAppOptions(newStudyOption);
        // setStudyAppOptions([...studyAppOptions, { label: 'Set round time centrally', value: 5 }]);
        // onChange(newArray);
        // studyAppOptions.push({ label: 'Set round time centrally', value: 5 });
      }
    } else if (newArray.length >= 0) {
      const index = newArray.findIndex((x) => x === item);
      if (!isEditing && !rerateElement) {
        setValue('rerateElement', true);
        setValue('promptTriggerTime', { minutes: 2, seconds: 0 });
      }
      // If theres no match add item to the array
      if (index === -1) {
        if (item === 4) {
          newArray.splice(0, newArray.length);
          newArray.push(item);
          resetField('allowRoundTimeEdit');
          resetField('roundTime');
          setDisabledStudyOptions([1, 2, 3]);
          const indx = studyAppOptions.findIndex((x) => x.value === 5);
          if (indx !== -1) {
            // studyAppOptions.splice(indx, 1);
            const filteredOptions = studyAppOptions.filter((option) => option.value !== 5);
            // setStudyAppOptions(filteredOptions);
          }
        } else {
          newArray.push(item);
        }
      } else {
        newArray.splice(index, 1);
        if (item === 5) {
          resetField('allowRoundTimeEdit');
          resetField('roundTime');
        }
        if (item === 4 && disabledStudyOptions?.length > 0) {
          setDisabledStudyOptions([]);
        }
      }
    } else {
      newArray.push(item);
    }

    onChange(newArray);
  };

  const maxFrequnecyFields = register('maxFrequency');
  const maxFrequnecy = watch('maxFrequency');

  return (
    <Container maxWidth="xxl">
      <Stack direction="ror" gap={2} alignItems="center">
        <IconButton
          size="small"
          sx={{
            color: 'primary.main',
            bgcolor: 'white',
            boxShadow: '0px 0px 6px rgba(0,0,0,0.2)',
            '&:hover': { bgcolor: '#f4f9ffcc' },
          }}
          onClick={handleBack}
        >
          <ArrowBackSharpIcon />
        </IconButton>
        <Typography variant="h4">{isEditing ? 'Edit Project' : 'Add Project'}</Typography>
      </Stack>
      {shouldRenderForm && (
        <Card elevation={6} sx={{ py: 2, mt: 2, mx: 16 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <DevTool control={control} /> */}
            <Grid container rowSpacing={2} columnSpacing={8} px={4}>
              <Grid item xs={12} md={6}>
                <Grid spacing={2} container>
                  <Grid item xs={12}>
                    <Typography variant="h6" my={0}>
                      Details
                    </Typography>
                  </Grid>
                  <Grid sx={{ '& .MuiFormControl-root': { mb: 0 }, '& .MuiCheckbox-root': { py: 0.5 } }} item xs={12} md={12}>
                    <FormInput
                      label="Project Name"
                      placeholder="Enter Name"
                      InputProps={{ ...register('name') }}
                      error={!!errors?.name}
                      helperText={errors?.name && errors?.name?.message}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
                    <label style={{ fontSize: '0.8600rem' }}>Customer</label>
                    <CustomSelect
                      name="customerID"
                      control={control}
                      options={customerOptions}
                      label="Select a Customer"
                      error={!!errors?.customerID}
                      helperText={errors?.customerID && errors?.customerID?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
                    <label style={{ fontSize: '0.8600rem' }}>Project Manager</label>
                    <CustomSelect
                      name="manager"
                      control={control}
                      options={managerOptions}
                      label="Select a Project Manager"
                      error={!!errors?.manager}
                      helperText={errors?.manager && errors?.manager?.message}
                    />
                  </Grid>
                  <Grid className="project-datepicker" item lg={12} md={12} xs={12}>
                    <label style={{ fontSize: '0.8600rem', marginBottom: 8, fontWeight: 'unset' }}>Study Date Range</label>
                    <Stack width="100%" sx={{ mt: '4px' }} direction="row">
                      <FormDatePicker
                        mb={0.1}
                        range="left"
                        name="startDate"
                        control={control}
                        error={!!errors?.startDate}
                        helperText={errors?.startDate && errors?.startDate?.message}
                        // value={startDate}
                        // onChange={(newDate) => setValue({ startDate: newDate })}
                        placeholder="From"
                        // onClear={() => setFromDate(null)}
                        height="1.8em !important"
                        // formLabel='Subscription End'
                      />
                      <FormDatePicker
                        mb={0.1}
                        range="right"
                        name="endDate"
                        control={control}
                        error={!!errors?.endDate}
                        helperText={errors?.endDate && errors?.endDate?.message}
                        // value={toDate}
                        // onChange={(newDate) => setToDate(newDate)}
                        placeholder="To"
                        // onClear={() => setToDate(null)}
                        height="1.8em !important"
                        // formLabel='Subscription End'
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormInput
                      label="Max Frequency Check"
                      placeholder="Max Frequency"
                      InputProps={{
                        name: maxFrequnecyFields.name,
                        onblur: maxFrequnecyFields.onBlur,
                        ref: maxFrequnecyFields.ref,
                        value: maxFrequnecy,
                        onChange: (event) => {
                          const newValue = event.target.value;
                          if (newValue === '' || Number(newValue) >= 0) {
                            return maxFrequnecyFields.onChange(event);
                          }
                          return null;
                        },
                      }}
                      type="number"
                      error={!!errors?.maxFrequency}
                      helperText={errors?.maxFrequency && errors?.maxFrequency?.message}
                    />
                    <Typography sx={{ ml: 1, mb: 2, display: 'flex', alignItems: 'center', gap: 0.6 }} variant="body2">
                      <ErrorOutlineOutlinedIcon sx={{ color: '#3c4555', fontSize: 'medium' }} />
                      Efficiency Study only. Leave empty for no limit
                    </Typography>
                  </Grid>
                  <Grid className="rerateElement" item xs={12} md={6}>
                    <ThemeSwitch justifyContent="flex-start" label="Re-rate element" control={control} name="rerateElement" />
                    <Typography sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 0.6 }} variant="body2">
                      <ErrorOutlineOutlinedIcon sx={{ color: '#3c4555', fontSize: 'medium' }} />
                      Activity & Role Study Only
                    </Typography>
                    {rerateElement && (
                      <Grid item xs={6} md={12}>
                        <DigitalClock name="promptTriggerTime" label="Trigger Time" register={register} control={control} />
                      </Grid>
                    )}
                  </Grid>
                  <Grid className="rerateElement" item xs={12} md={6}>
                    <Grid className="addSystemElement" item xs={12} md={12}>
                      <ThemeSwitch
                        justifyContent="flex-start"
                        label="Add System Elements"
                        control={control}
                        name="addSystemElement"
                      />
                    </Grid>
                    <Grid className="rating" item xs={12} md={12}>
                      <ThemeSwitch justifyContent="flex-start" label="Rating in use" control={control} name="rating" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid sx={{ '& .MuiCheckbox-root': { py: 0.5 } }} spacing={2} rowSpacing={4} container>
                  <Grid item xs={12}>
                    <Typography variant="h6" my={0}>
                      Permissions
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <CheckboxGroup
                      control={control}
                      label="Show project in App"
                      name="studyTypes"
                      options={studyAppOptions}
                      fontWeight="600"
                      config={studyAppConfig}
                      disableOptions={disabledStudyOptions}
                      onValueChange={onValueChange}
                    />
                    {isPredetermined && (
                      <>
                        {/* <FormLabel
                          sx={{
                            fontSize: '0.9375rem',
                            '&.MuiFormLabel-root': { color: '#212B36 !important' },
                            color: '#212B36',
                            fontWeight: 600,
                          }}
                          // {...LabelProps}
                          component="legend"
                        >
                          Levels
                        </FormLabel> */}
                        <ThemeCheckbox
                          label="Level 3 Standard Operations"
                          InputProps={{ ...register('level3') }}
                          control={control}
                        />
                      </>
                    )}
                    {shouldShowRoundTime && (
                      <ThemeCheckbox label="Set round time centrally" control={control} name="roundTimeBoolean" />
                    )}
                    {roundTimeBoolean && (
                      <Stack direction="column" gap={1}>
                        <label style={{ fontSize: '0.8600rem' }}>Round Time</label>
                        <CustomSelect
                          width="100%"
                          name="roundTime"
                          control={control}
                          options={roundTImeOptions}
                          label="Select Round Time"
                          error={!!errors?.roundTime}
                          helperText={errors?.roundTime && errors?.roundTime?.message}
                        />
                        <ThemeCheckbox label="Allow Edit Round Time" control={control} name="allowRoundTimeEdit" />
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <FormLabel
                      sx={{
                        fontSize: '0.9375rem',
                        '&.MuiFormLabel-root': { color: '#212B36 !important' },
                        color: '#212B36',
                        fontWeight: 600,
                      }}
                      component="legend"
                    >
                      Allow in App
                    </FormLabel>
                    <ThemeCheckbox label="Study Export" control={control} name="studyExport" />
                    <ThemeCheckbox label="Project Stats" control={control} name="projectStats" />
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <FormLabel
                      sx={{
                        fontSize: '0.9375rem',
                        '&.MuiFormLabel-root': { color: '#212B36 !important' },
                        color: '#212B36',
                        fontWeight: 600,
                      }}
                      component="legend"
                    >
                      Add Element in Apps
                    </FormLabel>
                    <ThemeCheckbox
                      color={getStudyColor(1)}
                      fontWeight="600"
                      label="Efficiency Study"
                      control={control}
                      name="elementCreationRAS"
                    />
                    <ThemeCheckbox
                      color={getStudyColor(2)}
                      fontWeight="600"
                      label="Role Study"
                      control={control}
                      name="elementCreationDILO"
                    />
                    <ThemeCheckbox
                      color={getStudyColor(3)}
                      fontWeight="600"
                      label="Activity Study"
                      control={control}
                      name="elementCreationDOTS"
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <FormLabel
                      sx={{
                        fontSize: '0.9375rem',
                        '&.MuiFormLabel-root': { color: '#212B36 !important' },
                        color: '#212B36',
                        fontWeight: 600,
                      }}
                      component="legend"
                    >
                      Add Area in Apps
                    </FormLabel>
                    <ThemeCheckbox
                      color={getStudyColor(1)}
                      fontWeight="600"
                      label="Efficiency Study"
                      control={control}
                      name="areaCreationRAS"
                    />
                    <ThemeCheckbox
                      color={getStudyColor(2)}
                      fontWeight="600"
                      label="Role Study"
                      control={control}
                      name="areaCreationDILO"
                    />
                    <ThemeCheckbox
                      color={getStudyColor(3)}
                      fontWeight="600"
                      label="Activity Study"
                      control={control}
                      name="areaCreationDOTS"
                    />
                  </Grid>

                  <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <FormLabel
                      sx={{
                        fontSize: '0.9375rem',
                        '&.MuiFormLabel-root': { color: '#212B36 !important' },
                        color: '#212B36',
                        fontWeight: 600,
                      }}
                      component="legend"
                    >
                      Add Role in Apps
                    </FormLabel>
                    <ThemeCheckbox
                      color={getStudyColor(1)}
                      fontWeight="600"
                      label="Efficiency Study"
                      control={control}
                      name="roleCreationRAS"
                    />
                    <ThemeCheckbox
                      color={getStudyColor(2)}
                      fontWeight="600"
                      label="Role Study"
                      control={control}
                      name="roleCreationDILO"
                    />
                  </Grid>

                  <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <FormLabel
                      sx={{
                        fontSize: '0.9375rem',
                        '&.MuiFormLabel-root': { color: '#212B36 !important' },
                        color: '#212B36',
                        fontWeight: 600,
                      }}
                      component="legend"
                    >
                      Others
                    </FormLabel>
                    <ThemeCheckbox
                      description="If ‘Checked’ the area will show after first customer count until the next round."
                      label="Multiple Counts per area"
                      control={control}
                      name="multipleCountsPerArea"
                    />
                    <ThemeCheckbox
                      description="If ‘Checked’ the area will show after first tracking count until the next round."
                      label="Multiple Tracking per area"
                      control={control}
                      name="multipleTrackingPerArea"
                    />
                    <ThemeCheckbox
                      description="If 'Checked' the exports will be in HH:mm:ss instead of decimal minutes."
                      label="Export in time format"
                      control={control}
                      name="exportInTimeFormat"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', p: 6 }}>
              <ThemeButton color="error" variant="outlined" size="large" onClick={handleBack} sx={{ mr: 1 }}>
                Cancel
              </ThemeButton>
              <ThemeButton loading={isAdding} type="submit" size="large" variant="contained">
                {isEditing ? 'Update' : 'Save'}
              </ThemeButton>
            </Box>
          </form>
        </Card>
      )}
      {isSingleProjectLoading && (
        <Card sx={{ py: 2, mt: 2, mx: 16, height: 800, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Card>
      )}
      <DevTool control={control} />
    </Container>
  );
};

export default AddProjectPage;

// const AddCustomer = () => {
//   const navigate = useNavigate();
//   const handleBack = () => {
//     navigate(-1); // This will take you back to the previous page
//   };
//   return (
//     <Container maxWidth="xxl">
//       <Stack direction="ror" gap={2} alignItems="center">
//         <IconButton
//           size="small"
//           sx={{
//             color: 'primary.main',
//             bgcolor: 'white',
//             boxShadow: '0px 0px 6px rgba(0,0,0,0.2)',
//             '&:hover': { bgcolor: '#f4f9ffcc' },
//           }}
//           onClick={handleBack}
//         >
//           <ArrowBackSharpIcon />
//         </IconButton>
//         <Typography variant="h4">Add Customer</Typography>
//       </Stack>
//       <AddCustomerStepper />
//     </Container>
//   );
// };
