import React from 'react';
import {
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Box,
  NativeSelect,
  InputBase,
  MenuList,
  OutlinedInput,
  FormHelperText,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

const CustomSelect = (props) => {
  const {
    id,
    defaultValue,
    label = '',
    formLabel,
    options,
    value,
    onChange,
    isNative,
    InputProps,
    error,
    helperText,
    FormHelperTextProps,
    control,
    name,
    renderValue,
    displayEmpty,
    multiple,
    isDisabled,
    isLoading,
    disabled,
    style,
    ...rest
  } = props;
  return (
    <Box {...rest}>
      {isNative ? (
        <FormControl fullWidth>
          <Select
            multiple={multiple}
            value={value}
            onChange={onChange}
            renderValue={renderValue}
            displayEmpty={displayEmpty}
            labelId="demo-simple-select-label"
            size="small"
            sx={{
              borderRadius: 25,
              height: 50,
            }}
            native
            id={id}
          >
            {options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Box {...rest}>
          <FormControl fullWidth>
            {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
            {formLabel && <label style={{ fontSize: '0.8600rem' }}>{formLabel}</label>}
            {!value && !control && (
              <InputLabel
                sx={{ lineHeight: '2.6em', fontSize: '0.86rem' }}
                size="small"
                shrink={false}
                id="demo-simple-select-label"
              >
                {label}
              </InputLabel>
            )}
            {control ? (
              <Controller
                control={control}
                name={name}
                // defaultValue={defaultValue}
                render={({ field }) => (
                  <>
                    {!field.value && (
                      <InputLabel
                        sx={{ lineHeight: '2.6em', fontSize: '0.86rem' }}
                        size="small"
                        shrink={false}
                        id="demo-simple-select-label"
                      >
                        {label}
                      </InputLabel>
                    )}
                    <Select
                      // defaultValue={defaultValue}
                      value={field?.value}
                      // onChange={onChange && onChange}
                      style={style}
                      onChange={field.onChange}
                      multiple={multiple}
                      renderValue={renderValue}
                      displayEmpty={displayEmpty}
                      labelId="demo-simple-select-label"
                      size="small"
                      id={id}
                      disabled={disabled}
                      inputProps={{
                        sx: { fontSize: '0.86rem' },
                      }}
                      sx={{
                        borderRadius: 25,
                        lineHeight: '1.64em',
                        mt: '5px',
                        // mb: '16px'
                      }}
                      error={error}
                      {...InputProps}
                      MenuProps={{ PaperProps: { sx: { maxHeight: 500 } } }}
                    >
                      {options?.length > 0 ? (
                        options?.map((option) => (
                          <MenuItem
                            disabled={isDisabled ? isDisabled(option) : false}
                            sx={{ paddingY: '1px', fontSize: '0.86rem' }}
                            value={option?.value}
                          >
                            {option?.label}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled value={null}>
                          No options found
                        </MenuItem>
                      )}
                    </Select>
                  </>
                )}
              />
            ) : (
              <Select
                // input={<BootstrapInput />}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange && onChange}
                labelId="demo-simple-select-label"
                size="small"
                inputProps={{
                  sx: { fontSize: '0.86rem' },
                }}
                sx={{
                  borderRadius: 25,
                  lineHeight: '1.64em',
                  mt: '5px',
                  // mb: '16px'
                }}
                error={error}
                {...InputProps}
                MenuProps={{ PaperProps: { sx: { maxHeight: 500 } } }}
              >
                {options?.length > 0 ? (
                  options?.map((option) => (
                    <MenuItem
                      disabled={isDisabled ? isDisabled(option) : false}
                      sx={{ paddingY: '1px', fontSize: '0.86rem' }}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={null}>No options found</MenuItem>
                )}
              </Select>
            )}

            {helperText && (
              <FormHelperText {...FormHelperTextProps} sx={{ color: error && 'error.main' }}>
                {helperText}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

CustomSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  control: PropTypes.any,
  onChange: PropTypes.func,
  isNative: PropTypes.bool,
  isDisabled: PropTypes.func,
  multiple: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  InputProps: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  formLabel: PropTypes.string,
  FormHelperTextProps: PropTypes.object,
  rest: PropTypes.object,
  renderValue: PropTypes.any,
  style: PropTypes.any,
};

export default CustomSelect;
