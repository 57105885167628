/* eslint-disable no-useless-escape */
import dayjs from 'dayjs';
import * as yup from 'yup';

const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const superUserSchema = yup.object({
  name: yup.string().trim().required('First Name is required'),
  lastname: yup.string().trim().required('Last Name is required'),
  email: yup.string().trim().required('Email is required').matches(EMAIL_REGEXP, 'Email format is not valid'),
  password: yup.string().trim().required('Password is required'),
});
export const loginSchema = yup.object({
  email: yup.string().trim().required('Email is required').matches(EMAIL_REGEXP, 'Email format is not valid'),
  password: yup.string().trim().required('Password is required'),
});
export const addExistingElementSchema = yup.object({
  currentElement: yup.string().trim().required('Element is required'),
});
export const addGroupSchema = yup.object({
  name: yup.string().trim().required('Group Name is required'),
});
export const addSystemElementSchema = yup.object({
  elementID: yup.string().trim().required('Element is required'),
});
export const studiesSchema = yup.object({
  studyName: yup.string().trim().required('Study Name is required'),
  locationID: yup.string().trim().required('Location is required'),
});
export const confirmPasswordSchema = yup.object({
  newPassword: yup.string().trim().required('New Password is required'),
  confirmPassword: yup
    .string()
    .trim()
    .required('New Password is required')
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});
export const resetPasswordSchema = yup.object({
  email: yup.string().trim().required('Email is required').matches(EMAIL_REGEXP, 'Email format is not valid'),
});
export const addIndustrySchema = yup.object({
  name: yup.string().trim().required('The Name is required'),
});
export const addAreaSchema = yup.object({
  name: yup.string().trim().required('Name is required'),
  studyTypes: yup.array().min(1, 'Select at least one study type'),
});
export const addTaskSchema = yup.object({
  name: yup.string().trim().required('Name is required'),
  controllingElementID: yup.string().trim().required('Controlling element is required'),
  elements: yup.array().of(yup.string().trim().required('Please select an element')).min(1, 'Select at least one element'),
  studyTypes: yup.array().min(1, 'Select at least one study type'),
});
export const addSectorsSchema = yup.object({
  name: yup.string().trim().required('Sector Name is required'),
  industryID: yup.string().trim().required('Industry is required'),
});
export const editIndustrySchema = yup.object({
  name: yup.string().trim().required('The Name is required'),
});
export const editSuperUserSchema = yup.object({
  name: yup.string().trim().required('First Name is required'),
  lastname: yup.string().trim().required('Last Name is required'),
  email: yup.string().trim().required('Email is required').matches(EMAIL_REGEXP, 'Email format is not valid'),
  // password: yup.string().trim().required('Password is required'),
});
export const editAdminUserSchema = yup.object({
  name: yup.string().trim().required('First Name is required'),
  lastname: yup.string().trim().required('Last Name is required'),
  email: yup.string().trim().required('Email is required').matches(EMAIL_REGEXP, 'Email format is not valid'),
  customerID: yup.string().trim().required('Customer is required'),
  permission: yup.string().required('Permission is required'),
});
export const addAdminUserSchema = yup.object({
  name: yup.string().trim().required('First Name is required'),
  lastname: yup.string().trim().required('Last Name is required'),
  email: yup.string().trim().required('Email is required').matches(EMAIL_REGEXP, 'Email format is not valid'),
  customerID: yup.string().trim().required('Customer is required'),
  password: yup.string().trim().required('Password is required'),
});
export const editFieldUserSchema = yup.object({
  name: yup.string().trim().required('First Name is required'),
  lastname: yup.string().trim().required('Last Name is required'),
  email: yup.string().trim().required('Email is required').matches(EMAIL_REGEXP, 'Email format is not valid'),
  customerID: yup.string().trim().required('Customer is required'),
});
export const addFieldUserSchema = yup.object({
  name: yup.string().trim().required('First Name is required'),
  lastname: yup.string().trim().required('Last Name is required'),
  email: yup.string().trim().required('Email is required').matches(EMAIL_REGEXP, 'Email format is not valid'),
  customerID: yup.string().trim().required('Customer is required'),
  password: yup.string().trim().required('Password is required'),
});
export const addQuestionScheme = yup.object({
  name: yup.string().trim().required('Question is required'),
  taskID: yup.string().when(['triggerType', 'studyTypes'], {
    is: (triggerType, studyTypes) =>
      triggerType === 'SHOW_ON_TASK_ELEMENTS' && (studyTypes.includes(2) || studyTypes.includes(3)),
    then: () => yup.string().trim().required('Task is required'),
    otherwise: () => yup.string().notRequired(),
  }),
  studyTypes: yup.array().min(1, 'Select at least one study type'),
  elements: yup.array().when(['triggerType'], {
    is: (triggerType) => triggerType === 'SHOW_ON_TASK_ELEMENTS',
    then: () => yup.array().min(1, 'Select at least one element'),
    otherwise: () => yup.array().notRequired(),
  }),

  showOnDays: yup.array().when('triggerType', {
    is: 'SHOW_ON_DAYS',
    then: () => yup.array().of(yup.string().trim().required('Date cannot be empty')).min(1, 'Select at least one date'),
  }),
  questionType: yup.string().required('Question type is required'),
  triggerType: yup.string().trim().required('Select atleast one trigger type'),
  // questionOptions: yup.array().of(yup.string().trim().required('Option cannot be empty')),
  questionOptions: yup.array().when('questionType', {
    is: (value) => value !== 'text',
    then: () => yup.array().of(yup.string().trim().required('Option cannot be empty')),
    otherwise: () => yup.array().notRequired(),
  }),
  triggerTime: yup.object().shape({
    hours: yup
      .number()
      .typeError('Hours must be a number')
      .min(0, 'Hours must be at least 0')
      .max(23, 'Hours must be at most 23')
      .required('Hours are required'),
    minutes: yup
      .number()
      .typeError('Minutes must be a number')
      .min(0, 'Minutes must be at least 0')
      .max(59, 'Minutes must be at most 59')
      .required('Minutes are required'),
  }),
});
export const addProjectFielduserSchema = yup.object({
  userID: yup.string().trim().required('Field User is required'),
  locations: yup.array().min(1, 'Select at least one Location'),
});
export const addCategorySchema = yup.object({
  name: yup.string().trim().required('Name is required'),
  analysisType: yup.string().required('Analysis Type is required'),
  mainCategory: yup.string().required('Main Category is required'),
});
export const addElementSchema = yup.object({
  name: yup.string().trim().required('Name is required'),
  categoryID: yup.string().required('Category is required'),
  relaxationAllowance: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .test('Is positive?', 'Must be a positive Number', (value) => value >= 0)
    .required('RA is required'),
  contingencyAllowance: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .test('Is positive?', 'Must be a positive Number', (value) => value >= 0)
    .required('CA is required'),
  studyTypes: yup.array().min(1, 'Select at least one study type'),
});
export const addElementInProjectSchema = yup.object({
  name: yup.string().trim().required('Name is required'),
  categoryID: yup.string().required('Category is required'),
  relaxationAllowance: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .test('Is positive?', 'Must be a positive Number', (value) => value >= 0)
    .required('RA is required'),
  contingencyAllowance: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .test('Is positive?', 'Must be a positive Number', (value) => value >= 0)
    .required('CA is required'),
  studyTypes: yup.array().min(1, 'Select at least one study type'),
  tasks: yup.array().of(
    yup.object().shape({
      taskID: yup.string().required('Task is required'),
    })
  ),
});
export const addRemindersSchema = yup.object({
  name: yup.string().trim().required('Name is required'),
  triggerType: yup.string().trim().required('Select atleast one trigger type'),
  studyTypes: yup.array().min(1, 'Select at least one study type'),
  showOnDays: yup.array().when('triggerType', {
    is: 'SHOW_ON_DAYS',
    then: () => yup.array().of(yup.string().trim().required('Date cannot be empty')).min(1, 'Select at least one date'),
  }),
  elements: yup.array().when(['triggerType'], {
    is: (triggerType) => triggerType === 'SHOW_IN_TASK_ELEMENT',
    then: () => yup.array().min(1, 'Select at least one element'),
    otherwise: () => yup.array().notRequired(),
  }),

  triggerTime: yup.object().shape({
    hours: yup
      .number()
      .typeError('Hours must be a number')
      .min(0, 'Hours must be at least 0')
      .max(23, 'Hours must be at most 23')
      .required('Hours are required'),
    minutes: yup
      .number()
      .typeError('Minutes must be a number')
      .min(0, 'Minutes must be at least 0')
      .max(59, 'Minutes must be at most 59')
      .required('Minutes are required'),
  }),
  taskID: yup.string().when(['triggerType', 'studyTypes'], {
    is: (triggerType, studyTypes) => triggerType === 'SHOW_IN_TASK_ELEMENT' && (studyTypes.includes(2) || studyTypes.includes(3)),
    then: () => yup.string().trim().required('Task is required'),
    otherwise: () => yup.string().notRequired(),
  }),
});
export const addUserSchema = yup.object({
  name: yup.string().trim().required('Name is required').trim(),
  industry: yup.string().trim().required('Industry is required').trim(),
  sector: yup.string().trim().required('Sector is required').trim(),
  // optOutFromBenchmarking: yup
  //   .bool() // use bool instead of boolean
  //   .oneOf([true], 'You must select this'),
  expiryDate: yup
    .object()
    .shape({
      jsdate: yup.date().typeError('Please enter a valid date').min(new Date(), 'Expiry date must be in the future'), // You can adjust the validation rules as needed
    })
    .required('Expiry date is required'),
  // expiryDate: yup.object().shape({
  //   jsdate: yup.date()
  //     .typeError('Please enter a valid date')
  //     .min(new Date(), 'Expiry date must be in the future'), // You can adjust the validation rules as needed
  // }).required('Expiry date is required'),
});
export const addRoleSchema = yup.object({
  name: yup.string().trim().required('Name is required').trim(),
  position: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required('Position is required'),
});
export const addObservationSchema = yup.object({
  frequency: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .when('elementID', {
      is: (elementID) => elementID != null && elementID !== '', // Validation applies if elementID exists
      then: (schema) => schema.required('Frequency is required'), // Apply required validation
      otherwise: (schema) => schema.nullable(), // Apply nullable validation otherwise
    }),
  elementID: yup.string().nullable(), // Define the elementID field if needed
});
export const addToObservationSchema = yup.object({
  areaID: yup.string().required('Area is required'), // Define the elementID field if needed
});
export const editRoleSchema = yup.object({
  name: yup.string().trim().required('Name is required').trim(),
  studyTypes: yup.array().min(1, 'Select at least one study type'),
});
export const settingsSchema = yup.object({
  passwordExpiryIntervalAdmin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('Must be a positive Number')
    .required('An expiry interval is required'),
  passwordExpiryIntervalUser: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('Must be a positive Number')
    .required('An expiry interval is required'),
  elementsCount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('Must be a positive Number')
    .required('Count is required'),
  SDMultiplyBy: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('Must be a positive Number')
    .required('An S.D. multiply by is required'),
  dataCoverage: yup.array().of(
    yup.lazy((item, option) => {
      if ('countIsEqualTo' in item) {
        return yup.object({
          countIsEqualTo: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .min(0, 'Must be at least 0')
            .required('Count is required'),
          countIsEqualToColor: yup
            .string()
            .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, 'Invalid color format')
            .required('Color is required'),
        });
      }

      if ('countIsGTE' in item) {
        return yup.object({
          countIsGTE: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .min(0, 'Must be at least 0')
            .required('Count is required'),
          countIsLTE: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .min(0, 'Must be at least 0')
            .required('Count is required'),
          countIsGTEAndLTEColor: yup
            .string()
            .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, 'Invalid color format')
            .required('Color is required'),
        });
      }
      if ('nextCountIsGTE' in item) {
        return yup.object({
          nextCountIsGTE: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .min(0, 'Must be at least 0')
            .required('Count is required'),
          nextCountIsGTEColor: yup
            .string()
            .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, 'Invalid color format')
            .required('Color is required'),
        });
      }
      return yup.object();
    })
  ),
});
export const addCustomerSchema = yup.object({
  name: yup.string().trim().required('Name is required').trim(),
  industryID: yup.string().trim().required('Industry is required').trim(),
  sectorID: yup.string().trim().required('Sector is required').trim(),
  expiryDate: yup
    .date()
    .typeError('Please enter a valid date')
    .min(new Date(), 'Expiry date must be in the future')
    .required('Expiry date is required'),
  allowedAmountOfUsers: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('Must be a positive Number')
    .required('This field is required'),
  userPermission: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('Must be a positive Number')
    .required('User Permission is required'),
  studyTypes: yup.array().min(1, 'At least one study type must be selected'),
  // expiryDate: yup.object().shape({
  //   jsdate: yup.date()
  //     .typeError('Please enter a valid date')
  //     .min(new Date(), 'Expiry date must be in the future'), // You can adjust the validation rules as needed
  // }).required('Expiry date is required'),
});
export const addProjectSchema = yup.object(
  {
    name: yup.string().trim().required('Name is required').trim(),
    customerID: yup.string().trim().required('Customer is required').trim(),
    manager: yup.string().trim().required('Project Manager is required').trim(),
    studyTypes: yup.array().min(1, 'At least one study type must be selected'),
    startDate: yup.date().typeError('Please enter a valid date').required('Date is required'),
    // endDate: yup.date().typeError('Please enter a valid date').required('Date is required'),
    endDate: yup
      .date()
      .typeError('Please enter a valid date')
      .when('startDate', (startDate, schema) => {
        if (startDate) {
          return schema
            .required('End date is required')
            .test('is-later-than-start', 'End date cannot be before the start date', function (endDate) {
              const { path, createError } = this;
              if (endDate && dayjs(endDate).isBefore(dayjs(startDate))) {
                return createError({ path, message: 'End date cannot be before the start date' });
              }
              return true;
            });
        }
        return schema; // No validation on endDate if startDate is not provided
      }),
  },
  [['endDate', 'startDate']]
);
export const addLocationSchema = yup.object({
  name: yup.string().trim().required('Location Name is required').trim(),
  postcode: yup.string().trim().required('Postcode is required').trim(),
  address: yup.string().trim().required('Address is required').trim(),
  tags: yup.array().of(yup.string().trim().required('Tags cannot be empty')),
});
