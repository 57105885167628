import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';
import PropTypes from 'prop-types';

export const ThemeButton = styled(LoadingButton)({
  color: 'primary.main',
  // padding: 8,
  // borderRadius: 4,
  borderRadius: '1.625rem',
  borderWidth: 2,
  fontWeight: 600,
  textTransform: 'none',
  '&:hover': { borderWidth: 2 },
});

const renderStartIcon = (type) => {
  if (type === 'activate') {
    return <PlayArrowOutlinedIcon />;
  }
  if (type === 'archive') {
    return <Inventory2OutlinedIcon />;
  }
  if (type === 'delete') {
    return <DeleteOutlineIcon />;
  }
  return null;
};

export const ActionButton = (props) => {
  const { children, type, ...rest } = props;
  return (
    <ThemeButton {...rest} startIcon={type && renderStartIcon(type)}>
      {children}
    </ThemeButton>
  );
};

ActionButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
};
