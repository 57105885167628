/* eslint-disable arrow-body-style */
import { Box, Card, Container, Divider, FormGroup, FormLabel, Grid, Typography } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import React, { useEffect, useMemo, useState } from 'react';
import { DevTool } from '@hookform/devtools';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemeButton } from '../../components/button';
import ThemeCheckbox from '../../components/checkbox/themeChekbox';
import FileUploadComponent from '../../components/fileUpload';
import FormInput from '../../components/formComponents/FormInput';
import FormDatePicker from '../../components/formComponents/formDatePicker';
import CustomSelect from '../../components/select';
import CustomizedSteppers from '../../components/stepper';
import { getIndustries } from '../industry/industrySlice';
import { getSectors } from '../sector/sectorsSlice';
import CheckboxGroup from '../../components/checkbox/CheckboxGroup';
import GroupCheckbox from '../../components/formComponents/FormCheckbox';
import FormRadioGroup from '../../components/formComponents/formRadioGroup';
import { addCustomerSchema } from '../../utils/schema';
import { addCustomer, editCustomer, getCustomers, uploadImage } from './customerSlice';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { APP_ROUTER_BASE_URL } from '../../utils';
import axiosInstance from '../../utils/axiosInstance';

const AddCustomerStepper = ({ rowToEdit }) => {
  const { industries } = useSelector((state) => state.industry);
  const { sectors } = useSelector((state) => state.sectors);
  const dispatch = useDispatch();
  const steps = [
    { label: 'Photo', icon: <PermIdentityOutlinedIcon /> },
    { label: 'Details', icon: <DisplaySettingsOutlinedIcon /> },
    { label: 'Permission', icon: <GppGoodOutlinedIcon /> },
  ];
  const [image, setImage] = useState('');
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [responseImage, setResponseImage] = useState('');
  const form = useForm({
    defaultValues: {
      allowedAmountOfUsers: null,
      industryID: '',
      name: '',
      sectorID: '',
      allowStudyNameEdit: false,
      autoGenerateStudyNames: true,
      optOutFromBenchmarking: false,
      // expiryDate: '2024-01-01T18:30:00.000Z',
      expiryDate: null,
      studyTypes: [],
      userPermission: null,
      image: '',
    },
    resolver: yupResolver(addCustomerSchema),
    mode: 'onChange',
  });
  const {
    register,
    control,
    watch,
    trigger,
    getFieldState,
    setValue,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
  } = form;

  const fetchRowDetails = async () => {
    if (rowToEdit) {
      try {
        const response = await axiosInstance({
          url: `customers/get/${rowToEdit}`,
          method: 'GET',
        });
        if (get(response, 'data.data')) {
          setValue('name', get(response, 'data.data.name'));
          setValue('expiryDate', get(response, 'data.data.expiryDate'));
          setValue('industryID', get(response, 'data.data.industryID'));
          setValue('allowedAmountOfUsers', get(response, 'data.data.allowedAmountOfUsers'));
          setValue('sectorID', get(response, 'data.data.sectorID'));
          setValue('allowStudyNameEdit', get(response, 'data.data.allowStudyNameEdit'));
          setValue('autoGenerateStudyNames', get(response, 'data.data.autoGenerateStudyNames'));
          setValue('optOutFromBenchmarking', get(response, 'data.data.optOutFromBenchmarking'));
          setValue('studyTypes', get(response, 'data.data.studyTypes'));
          setValue('userPermission', get(response, 'data.data.userPermission'));
          setValue('level3', get(response, 'data.data.level3'));
          setValue(
            'image',
            get(response, 'data.data.image') && `https://server106.absolutebyte.co.uk/${get(response, 'data.data.image')}`
          );
          if (get(response, 'data.data.image')) {
            setResponseImage(`https://server106.absolutebyte.co.uk/${get(response, 'data.data.image')}`);
          }
        }
      } catch (error) {
        console.log('error -> getUsersById');
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  useEffect(() => {
    if (rowToEdit) {
      fetchRowDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowToEdit]);

  const [activeStep, setActiveStep] = useState(0);
  const [industryOptions, setIndustryOptions] = useState([
    { label: 'All Industries', value: '' },
    ...industries.map((industryID) => ({ label: industryID?.name, value: industryID?._id })),
  ]);
  const [sectorOptions, setSectorOptions] = useState([
    // { label: 'All Sectors', value: '' },
    // ...sectors.map((sectorID) => ({ label: sectorID?.name, value: sectorID?._id })),
  ]);
  useEffect(() => {
    dispatch(getIndustries());
    dispatch(getSectors());
  }, [dispatch]);
  const selectedIndustry = watch('industryID');
  useEffect(() => {
    setIndustryOptions([...industries.map((industryID) => ({ label: industryID?.name, value: industryID?._id }))]);
  }, [industries]);
  useEffect(() => {
    if (selectedIndustry) {
      const filteredSectorOptions = sectors.reduce((options, sector) => {
        if (sector.industryID === selectedIndustry) {
          options.push({ label: sector.name, value: sector._id });
        }
        return options;
      }, []);
      setSectorOptions([...filteredSectorOptions]);
      if (getFieldState('industryID').isDirty) {
        setValue('sectorID', '');
      }
    }
  }, [getFieldState, sectors, selectedIndustry, setValue]);
  const handleNext = async (event) => {
    event.preventDefault();
    let isValid = true;
    switch (activeStep) {
      case 1:
        isValid = await (rowToEdit
          ? trigger([
              // getFieldState('expiryDate').isDirty ? 'expiryDate' : '',
              'expiryDate',
              'name',
              'industryID',
              'sectorID',
              'allowedAmountOfUsers',
            ])
          : trigger(['name', 'expiryDate', 'industryID', 'sectorID', 'allowedAmountOfUsers']));
        break;
      default:
        isValid = true;
    }
    if (isValid) {
      // setActiveStep((prevState) => prevState + 1);
      setActiveStep(activeStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const studyAppConfig = { label: 'label', value: 'value' };
  const studyAppOptions = [
    { label: 'Efficiency Study', value: 1 },
    { label: 'Role Study', value: 2 },
    { label: 'Activity Study', value: 3 },
    // { label: 'Predetermined Study', value: 4 },
  ];
  const userPermissionOptions = [
    { label: 'Project Manager', value: 1 },
    { label: 'View Only/Field User', value: 2 },
    // { label: 'View Only', value: 3 },
  ];

  useEffect(() => {
    if (getValues('autoGenerateStudyNames') === false) {
      setValue('allowStudyNameEdit', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('autoGenerateStudyNames')]);

  const handleStudyChange = (event, value) => {
    const studies = getValues('studyTypes');
    if (studies.indexOf(value) !== -1) {
      const newValues = [...studies];
      const indexToRemove = studies.indexOf(value);
      if (indexToRemove !== -1) {
        newValues.splice(indexToRemove, 1);
      }
      setValue('studyTypes', newValues);
    } else {
      const newValue = [...studies, value];
      setValue(
        'studyTypes',
        newValue.sort((a, b) => a - b)
      );
    }
  };
  const isStudyChecked = (value) => {
    const studies = watch('studyTypes');
    return studies.indexOf(value) !== -1;
  };
  const studyTypes = watch('studyTypes');
  const isLevel3 = watch('level3');
  const navigate = useNavigate();
  const isPredetermined = useMemo(() => !!(studyTypes?.length && studyTypes.indexOf(4) !== -1), [studyTypes]);
  useEffect(() => {
    if (studyTypes?.length && studyTypes.indexOf(4) === -1 && isLevel3) {
      setValue('level3', false);
    }
  }, [isLevel3, setValue, studyTypes]);
  const onSubmit = (data) => {
    if (data) {
      const { image: imageUrl, ...rest } = data;
      const payload = { ...rest };
      if (imageUrl && isImageChanged && !image) {
        payload.image = image;
      }
      if (rowToEdit) {
        dispatch(editCustomer({ ...payload, _id: rowToEdit })).then((response) => {
          if (response.payload?.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message' || 'User Updated Successfully'),
              })
            );
            if (get(response, 'payload.data._id') && isImageChanged && image) {
              dispatch(uploadImage({ customerID: response?.payload?.data?._id, image })).then((res) => {
                navigate(`${APP_ROUTER_BASE_URL}customer`);
              });
            } else {
              navigate(`${APP_ROUTER_BASE_URL}customer`);
            }
          } else {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        });
      } else {
        dispatch(addCustomer(payload))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'User Added Successfully'),
                })
              );
              if (get(response, 'payload.data._id') && isImageChanged && image) {
                dispatch(uploadImage({ customerID: response?.payload?.data?._id, image })).then((res) => {
                  navigate(`${APP_ROUTER_BASE_URL}customer`);
                });
              } else {
                navigate(`${APP_ROUTER_BASE_URL}customer`);
              }
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
    // console.log('datae->>', data.expiryDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
  };
  const renderComponent = (currentStep) => {
    if (currentStep === 0) {
      return (
        // <FormDatePicker control={control} label='Date Testing' />
        // <FileUploadComponent image={watch('image')} setImage={setImage} setValue={setValue} />
        <FileUploadComponent
          responseImage={responseImage}
          setResponseImage={setResponseImage}
          image={image}
          setImage={setImage}
          setValue={setValue}
          setIsImageChanged={setIsImageChanged}
          isImageChanged={isImageChanged}
        />
      );
    }
    if (currentStep === 1) {
      return (
        <Grid container columnSpacing={8} px={8}>
          <Grid item xs={12} md={6}>
            <FormInput
              label="Name"
              placeholder="Enter Customer Name"
              InputProps={{ ...register('name') }}
              error={!!errors?.name}
              helperText={errors?.name && errors?.name?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDatePicker
              name="expiryDate"
              // placeholder="Choose expiration date"
              control={control}
              formLabel="Subscription End"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
            <label style={{ fontSize: '0.8600rem' }}>Industry</label>
            <CustomSelect
              name="industryID"
              control={control}
              options={industryOptions}
              label="Select a Industry"
              error={!!errors?.industryID}
              helperText={errors?.industryID && errors?.industryID?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              label="Max amount of users"
              InputProps={{ ...register('allowedAmountOfUsers') }}
              type="number"
              placeholder="Amount"
              error={!!errors?.allowedAmountOfUsers}
              helperText={errors?.allowedAmountOfUsers && errors?.allowedAmountOfUsers?.message}
              mb={1}
            />
            <Typography sx={{ ml: 1, mb: 2, display: 'flex', alignItems: 'center', gap: 0.6 }} variant="body2">
              <ErrorOutlineOutlinedIcon sx={{ color: '#3c4555', fontSize: 'medium' }} />
              Includes both Admin and Field users
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
            <label style={{ fontSize: '0.8600rem' }}>Sector</label>
            <CustomSelect
              name="sectorID"
              control={control}
              options={sectorOptions}
              label="Select a Sector"
              error={!!errors?.sectorID}
              helperText={errors?.sectorID && errors?.sectorID?.message}
            />
          </Grid>
        </Grid>
      );
    }
    if (currentStep === 2) {
      return (
        <Grid container columnSpacing={8} px={8}>
          <Grid item xs={12} md={6}>
            <CheckboxGroup
              control={control}
              label="Study / App permissions"
              name="studyTypes"
              options={studyAppOptions}
              config={studyAppConfig}
            />
            {/* <GroupCheckbox label="Study / App permissions">
              <FormGroup>
                <ThemeCheckbox
                  label="Efficient Study"
                  checked={isStudyChecked(studyAppPermissions.efficientStudy)}
                  onChange={(event) => handleStudyChange(event, studyAppPermissions.efficientStudy)}
                />
                <ThemeCheckbox
                  label="Role Study"
                  checked={isStudyChecked(studyAppPermissions.roleStudy)}
                  onChange={(event) => handleStudyChange(event, studyAppPermissions.roleStudy)}
                />
                <ThemeCheckbox
                  label="Activity Study"
                  checked={isStudyChecked(studyAppPermissions.activityStudy)}
                  onChange={(event) => handleStudyChange(event, studyAppPermissions.activityStudy)}
                />
                <ThemeCheckbox
                  label="Predetermined Study"
                  checked={isStudyChecked(studyAppPermissions.predeterminedStudy)}
                  onChange={(event) => handleStudyChange(event, studyAppPermissions.predeterminedStudy)}
                />
              </FormGroup>
            </GroupCheckbox> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                '& .MuiFormLabel-root .Mui-error ': { color: '#212B36 !important', fontWeight: '600' },
                width: '80%',
              }}
            >
              <GroupCheckbox
                error={!!errors?.userPermission}
                helperText={errors?.userPermission && errors?.userPermission?.message}
                label="User Permissions"
              >
                <FormRadioGroup fontSize={18} options={userPermissionOptions} control={control} name="userPermission" />
              </GroupCheckbox>
            </Box>
            {/* <GroupCheckbox label="User Permissions">
              <FormRadioGroup options={options} control={control} name="userPermission" />
            </GroupCheckbox> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel
              sx={{
                fontSize: '0.9375rem',
                '&.MuiFormLabel-root': { color: '#212B36 !important' },
                color: '#212B36',
                fontWeight: 600,
              }}
              // {...LabelProps}
              component="legend"
            >
              Study Names
            </FormLabel>
            <FormGroup
              sx={{
                fontSize: '0.9375rem',
                '&.MuiFormLabel-root': { color: '#212B36 !important' },
                color: '#212B36',
                fontWeight: 600,
              }}
            >
              <ThemeCheckbox label="Auto Generate" InputProps={{ ...register('autoGenerateStudyNames') }} control={control} />
              {watch('autoGenerateStudyNames') && (
                <ThemeCheckbox label="Allow Edit" InputProps={{ ...register('allowStudyNameEdit') }} control={control} />
              )}
            </FormGroup>
          </Grid>
        </Grid>
      );
    }
    return null;
  };
  return (
    <Container maxWidth="lg">
      <Card elevation={6} sx={{ p: 2, mt: 2, pt: 4 }}>
        <CustomizedSteppers steps={steps} activeStep={activeStep} />
        <Divider sx={{ mx: 8, my: 2, mb: 4 }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {renderComponent(activeStep)}
          {/* <DevTool control={control} /> */}
          <Box sx={{ display: 'flex', flexDirection: 'row', p: 6 }}>
            <ThemeButton
              color="error"
              variant="outlined"
              size="large"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </ThemeButton>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep === steps.length - 1 ? (
              <ThemeButton type="submit" size="large" variant="contained">
                Submit
              </ThemeButton>
            ) : (
              <ThemeButton type="button" size="large" variant="contained" onClick={handleNext}>
                Next
              </ThemeButton>
            )}
          </Box>
        </form>
      </Card>
      <DevTool control={control} />
    </Container>
  );
};

AddCustomerStepper.propTypes = {
  rowToEdit: PropTypes.string,
};

export default AddCustomerStepper;
