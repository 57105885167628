import { DevTool } from '@hookform/devtools';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {
  Box,
  Checkbox,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from '@mui/material';
import { get, has, isEmpty, isUndefined, repeat } from 'lodash';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../components/ModalComponent';
import StudiesComponent from '../../components/StudiesComponent';
import { ThemeButton } from '../../components/button';
import FormInput from '../../components/formComponents/FormInput';
import MultiSelect from '../../components/multiSelect';
import StatusIndicator from '../../components/status';
import ThemeSwitch from '../../components/switch';
import DataTable from '../../components/table';
import { Types } from '../../types';
import { checkboxStyling, getLabelStyles, showQuestionTypes, studyTypes } from '../question/config';
import Datetime from './Datetime';
import { addReminder, getReminders, updateReminder } from './remindersSlice';
import { addRemindersSchema } from '../../utils/schema';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import CheckboxGroup from '../../components/checkbox/CheckboxGroup';
import axiosInstance from '../../utils/axiosInstance';
import { getElements } from '../projectList/elements/elementsSlice';
import { getTasks } from '../tasksPage/taskSlice';
import CustomSelect from '../../components/select';

export const ThemeToggleButton = styled((props) => <ToggleButton {...props} />)(({ theme, props }) => ({
  borderRadius: '25px',
  '&.MuiToggleButton-root': {
    fontSize: '0.86rem',
    paddingInline: 0,
    border: '1px solid #919eab3d !important',
  },
  '&.MuiToggleButton-root.Mui-selected': {
    border: '1px solid #00c0f3 !important',
  },
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '23%',
  },
  [theme.breakpoints.up('md')]: {
    width: '23%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '20%',
  },
}));

const RemindersPage = ({ projectID, customerID }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (projectID) {
      dispatch(getReminders({ projectID }));
      dispatch(getTasks({ projectID }));
      dispatch(getElements({ projectID, defaultEntry: false }));
    }
  }, [dispatch, projectID]);

  const { reminders, isLoading, isAdding } = useSelector((state) => state?.reminders);
  const { elements: projectElements } = useSelector((state) => state.projectElements);
  const { tasks } = useSelector((state) => state.tasks);
  const getTriggerTypeLabel = (triggerType) => {
    switch (triggerType) {
      case 'TIME_IN_STUDY':
        return 'Time in study';

      case 'TIME_OF_DAY':
        return 'Time of day';

      case 'SHOW_IN_TASK_ELEMENT':
        return 'Show in Task/Element';

      case 'SHOW_ON_DAYS':
        return 'Show on days';

      default:
        return '';
    }
  };
  const headCells = [
    {
      id: 'name',
      numeric: false,
      // disablePadding: true,
      label: 'Description',
      titleCase: true,
      // align: 'left',
    },
    {
      id: 'triggerTime',
      numeric: false,
      // disablePadding: true,
      label: 'Trigger Time',
      disableSort: true,
      // titleCase: true,
      // align: 'left',
    },
    {
      id: 'triggerTypeLabel',
      numeric: false,
      // disablePadding: true,
      label: 'Trigger Type',
      titleCase: false,
      // align: 'left',
    },
    {
      id: 'studiesLength',
      disablePadding: false,
      // minWidth: '360px',
      // maxWidth: '360px',
      label: 'Studies',
      align: 'center',
      renderElement: (cell) => <StudiesComponent studyTypes={get(cell, 'projectSetting.studyTypes')} />,
    },
    {
      id: 'status',
      numeric: false,
      // disablePadding: true,
      // align: 'left',
      label: 'Active',
      renderElement: (cell) =>
        !isUndefined(cell, 'projectSetting.status') && get(cell, 'projectSetting.status') === 'active' ? (
          <CheckCircleOutlineOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="success" />
        ) : (
          <CancelOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="error" />
        ),
    },
    {
      id: 'repeat',
      numeric: false,
      // disablePadding: true,
      // align: 'left',
      label: 'Repeat',
      renderElement: (cell) =>
        !isUndefined(cell, 'projectSetting.repeat') && get(cell, 'projectSetting.repeat') ? (
          <CheckCircleOutlineOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="success" />
        ) : (
          <CancelOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="error" />
        ),
    },
    {
      id: 'takePhoto',
      numeric: false,
      // disablePadding: true,
      // align: 'left',
      label: 'Photo',
      renderElement: (cell) =>
        !isUndefined(cell, 'projectSetting.takePhoto') && get(cell, 'projectSetting.takePhoto') ? (
          <CheckCircleOutlineOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="success" />
        ) : (
          <CancelOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="error" />
        ),
    },
    // {
    //   id: 'status',
    //   label: 'Status',
    //   renderElement: (cell) => has(cell, 'projectSetting.status') && <StatusIndicator cell={cell?.projectSetting} />,
    // },
  ];
  const regularReminders = useMemo(
    () =>
      !isEmpty(reminders) &&
      !!reminders?.length &&
      reminders.reduce(
        (acc, reminder) => {
          const reminderObject = {
            ...reminder,
            triggerTypeLabel: getTriggerTypeLabel(get(reminder, 'projectSetting.triggerType')),
            status: get(reminder, 'projectSetting.status'),
            task: get(reminder, 'projectSetting.taskID', ''),
            elements: get(reminder, 'projectSetting.elements', []),
            repeat: get(reminder, 'projectSetting.repeat', false),
            takePhoto: get(reminder, 'projectSetting.takePhoto', false),
            triggerTime:
              get(reminder, 'projectSetting.triggerTime') &&
              `${get(reminder, 'projectSetting.triggerTime.hours', 0)}h ${get(reminder, 'projectSetting.triggerTime.minutes', 0)}m`,
          };
          if (reminder?.projectSetting?.status === 'active') {
            acc.active.push(reminderObject);
          } else if (reminder?.projectSetting?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(reminderObject);
          }
          acc.default.push(reminderObject);
          return acc;
        },
        { active: [], archive: [], default: [] }
      ),
    [reminders]
  );
  const [reminderRows, setReminderRows] = useState([]);
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [isModalopen, setisModalopen] = useState(false);
  const [Status, setStatus] = useState(ACTIVE);
  const [rowToEdit, setRowToEdit] = useState('');
  const [disabledStudyOptions, setDisabledStudyOptions] = useState([]);
  useEffect(() => {
    if (regularReminders) {
      if (Status === DEFAULT) {
        setReminderRows(regularReminders?.default);
      } else if (Status === ARCHIVED) {
        setReminderRows(regularReminders?.archive);
      } else if (Status === ACTIVE) {
        setReminderRows(regularReminders?.active);
      }
    } else {
      setReminderRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularReminders]);
  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setReminderRows(regularReminders.default);
      } else if (value === ACTIVE) {
        setReminderRows(regularReminders.active);
      } else if (value === ARCHIVED) {
        setReminderRows(regularReminders.archive);
      }
    }
  };
  const form = useForm({
    defaultValues: {
      name: '',
      takePhoto: false,
      studyTypes: [],
      repeat: false,
      active: false,
      status: 'active',
      photo: false,
      taskID: '',
      elements: [],
      showOnDays: [],
      text: '',
      triggerType: '',
      triggerTime: {
        hours: 0,
        minutes: 0,
      },
    },
    resolver: yupResolver(addRemindersSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const studyTypesValues = watch('studyTypes');
  const taskIDValue = watch('taskID');
  const elementsOptions = useMemo(() => {
    let options = [];
    let filteredOptions = [];
    options =
      projectElements?.length >= 0 &&
      projectElements
        ?.filter((element) => element?.projectSetting?.status === 'active')
        ?.map((element) => ({ label: element?.name, value: element?._id }));
    if (
      projectElements?.length >= 0 &&
      studyTypesValues?.length > 0 &&
      (studyTypesValues.includes(2) || studyTypesValues.includes(3))
    ) {
      if (taskIDValue && tasks?.length >= 0) {
        const task = tasks.find((field) => field?._id === taskIDValue);
        const taskElements = get(task, 'projectSetting.elements', []);
        filteredOptions = options?.length > 0 ? options.filter((option) => taskElements?.includes(option?.value)) : [];
      }
    } else {
      filteredOptions = options;
    }
    return filteredOptions;
  }, [projectElements, studyTypesValues, taskIDValue, tasks]);
  const tasksOptions = useMemo(
    () =>
      tasks?.length > 0
        ? tasks
            ?.filter((taskElement) => taskElement?.projectSetting.status === 'active')
            ?.map((taskElement) => ({ label: taskElement?.name, value: taskElement?._id }))
        : [],
    [tasks]
  );
  useEffect(() => {
    if (studyTypesValues.includes(1)) {
      setDisabledStudyOptions([2, 3]);
    } else if (studyTypesValues.includes(2) || studyTypesValues.includes(3)) {
      setDisabledStudyOptions([1]);
    } else {
      setDisabledStudyOptions([]);
    }
  }, [setValue, studyTypesValues]);

  const openModal = () => setisModalopen(true);
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    reset();
    // reset({ permission: '' });
    // setShowPassword(false);
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };
  const triggerType = watch('triggerType');
  const renderHelperComponents = () => {
    switch (watch('triggerType')) {
      case showQuestionTypes?.dateTime:
        return (
          <Datetime
            setValue={setValue}
            dateError={errors?.showOnDays || null}
            label="Time of day"
            register={register}
            control={control}
          />
        );
      case showQuestionTypes?.timeOfDay:
        return <Datetime setValue={setValue} disableDate label="Trigger Time" register={register} control={control} />;
      case showQuestionTypes?.duration:
        return <Datetime setValue={setValue} disableDate label="Time from study start" register={register} control={control} />;
      case showQuestionTypes?.element:
        return (
          <Stack>
            {(studyTypesValues.includes(2) || studyTypesValues.includes(3)) && (
              <Box>
                {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
                <label style={{ fontSize: '0.8600rem' }}>Task</label>
                <CustomSelect
                  name="taskID"
                  control={control}
                  options={tasksOptions}
                  label="Select Task"
                  // width="95%"
                  error={!isEmpty(get(errors, 'taskID'))}
                  helperText={has(errors, 'taskID.message') && get(errors, 'taskID.message')}
                />
              </Box>
            )}
            <MultiSelect
              name="elements"
              multiple
              getOptionLabel={(option) => option.label ?? option}
              control={control}
              options={elementsOptions}
              label="Elements"
              placeholder={!watch('elements')?.length && 'Select Elements'}
              error={!isEmpty(get(errors, 'elements'))}
              helperText={has(errors, 'elements.message') && get(errors, 'elements.message')}
            />
          </Stack>
        );
      default:
        return null;
    }
  };
  const getPayload = (selected, status, id) => ({ reminderIDs: selected, status, projectID: id });
  const fetchData = () => {
    dispatch(getReminders({ projectID }));
  };
  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search Reminder',
    openModal,
    addButtonName: 'Create Reminder',
    getPayload,
    fetchData,
    isRemindersPage: true,
  };
  const { singleProjectData } = useSelector((state) => state.project);
  const onEditClick = async (event, row) => {
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      const reminderToEdit = reminders.find((area) => area?._id === row?._id);
      setValue('name', get(reminderToEdit, 'name', ''));
      setValue('studyTypes', get(reminderToEdit, 'projectSetting.studyTypes', []));
      setValue('elements', get(reminderToEdit, 'projectSetting.elements', []));
      setValue('taskID', get(reminderToEdit, 'projectSetting.taskID', ''));
      setValue('repeat', get(reminderToEdit, 'projectSetting.repeat', false));
      setValue('takePhoto', get(reminderToEdit, 'projectSetting.takePhoto', false));
      setValue('showOnDays', get(reminderToEdit, 'projectSetting.showOnDays', []));
      setValue('triggerTime', get(reminderToEdit, 'projectSetting.triggerTime', { hours: 0, minutes: 0 }));
      setValue('triggerType', get(reminderToEdit, 'projectSetting.triggerType', ''));
      openModal();
    } else {
      console.warn('No Row Id found');
    }
  };
  const onSubmit = (values) => {
    if (projectID && customerID && values && values?.triggerType) {
      const { triggerTime, showOnDays, elements, taskID, repeat: repeatValue, ...rest } = values;
      const payload = { ...rest };

      if (values?.triggerType === showQuestionTypes?.duration) {
        payload.repeat = repeatValue;
      }
      if (values?.triggerType === showQuestionTypes?.element) {
        payload.elements = elements;
        payload.repeat = false;
        payload.triggerTime = { hours: 0, minutes: 0 };
        if (!values?.studyTypes?.includes(1)) {
          payload.taskID = taskID;
        }
      } else if (values?.triggerType === showQuestionTypes?.dateTime) {
        payload.repeat = false;
        payload.triggerTime = triggerTime;
        payload.showOnDays = showOnDays;
      } else {
        payload.triggerTime = triggerTime;
        if (values?.triggerType !== showQuestionTypes?.duration) {
          payload.repeat = false;
        }
      }
      if (rowToEdit) {
        dispatch(updateReminder({ ...payload, customerID, projectID, _id: rowToEdit }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Reminder Updated Successfully'),
                })
              );
              dispatch(getReminders({ projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      } else {
        dispatch(addReminder({ ...payload, customerID, projectID }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Reminder Added Successfully'),
                })
              );
              dispatch(getReminders({ projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'reminders/update/status',
          method: 'PATCH',
          data: { reminderIDs: [row?._id], status, projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Reminder`,
            })
          );
          dispatch(getReminders({ projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id && projectID) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `reminders/delete/${row?._id}`,
          method: 'DELETE',
          data: { projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getReminders({ projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const studyAppConfig = { label: 'label', value: 'value' };
  const studyAppOptions = useMemo(() => {
    const defaultStudyAppOptions = [
      { label: 'Efficiency Study', value: 1, color: '#f59d0f' },
      { label: 'Role Study', value: 2, color: '#784fc2' },
      { label: 'Activity Study', value: 3, color: '#0ba0ef' },
      // { label: 'Predetermined Study', value: 4, color: '#9CC13B' },
    ];
    if (defaultStudyAppOptions && singleProjectData && singleProjectData?.studyTypes) {
      return defaultStudyAppOptions?.filter((option) => singleProjectData?.studyTypes?.includes(option?.value));
    }
    return [];
  }, [singleProjectData]);
  const handleCheckboxChange = (studyType) => {
    const currentValues = getValues('studyTypes') || [];
    const updatedValues = currentValues.includes(studyType)
      ? currentValues.filter((value) => value !== studyType)
      : [...currentValues, studyType];

    setValue('studyTypes', updatedValues);
  };
  const onValueChange = (onChange, value, item) => {
    const newArray = [...value];
    if (newArray.length > 0) {
      const index = newArray.findIndex((x) => x === item);

      // If theres no match add item to the array
      if (index === -1) {
        newArray.push(item);
      } else {
        newArray.splice(index, 1);
      }
    } else {
      if (item === 2 || item === 3) {
        setValue('elements', []);
      } else {
        setValue('taskID', '');
      }
      newArray.push(item);
    }

    onChange(newArray);
  };
  const hideNestedFunc = (row) => {
    const isELement = get(row, 'projectSetting.triggerType') !== 'SHOW_IN_TASK_ELEMENT';
    return isELement;
  };
  return (
    <Box>
      <ModalComponent
        persist
        maxWidth="lg"
        PaperProps={{
          sx: {
            width: '100%',
            // maxWidth: '600px !important',
            // maxHeight: '700px',
          },
        }}
        title={rowToEdit ? 'Edit Reminder' : 'Add Reminder'}
        // title="Add Area"
        open={isModalopen}
        onClose={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid sx={{ minHeight: 270 }} container justifyContent="space-between" spacing={6}>
            {/* <Grid columnSpacing={3} rowSpacing={1} container justifyContent="space-between"> */}
            <Grid item lg={5.8} sm={12} xs={12} md={12}>
              <FormInput
                label="Description"
                placeholder="What do you need?"
                InputProps={{
                  ...register('name'),
                }}
                error={!!errors?.name}
                helperText={errors?.name && errors?.name?.message}
              />
              <Stack justifyContent="space-between" direction={{ sm: 'column', md: 'row' }}>
                <CheckboxGroup
                  control={control}
                  label="Study Types"
                  name="studyTypes"
                  onValueChange={onValueChange}
                  options={studyAppOptions}
                  fontWeight="600"
                  config={studyAppConfig}
                  disableOptions={disabledStudyOptions}
                />

                <Stack>
                  <ThemeSwitch label="Take photo" control={control} name="takePhoto" width="100%" />
                  {triggerType === showQuestionTypes.duration && (
                    <ThemeSwitch label="Repeat" control={control} name="repeat" width="100%" />
                  )}
                </Stack>
              </Stack>
              {/* </Grid>
            <Grid item lg={6} sm={12} xs={12} md={6}> */}
            </Grid>
            {/* <Grid className="count" item lg={6} sm={12} xs={12} md={6}></Grid> */}
            {/* </Grid> */}
            <Grid item lg={5.8} md={12} sm={12} xs={12}>
              <Typography sx={{ mt: 0, fontWeight: 600 }} variant="body1">
                Show Reminder On
              </Typography>
              <Controller
                control={control}
                name="triggerType"
                render={({ field }) => (
                  <ToggleButtonGroup
                    sx={{ width: '100%', flexWrap: 'wrap', mb: 2 }}
                    color="primary"
                    exclusive
                    ref={field.ref}
                    {...field}
                    value={field.value}
                  >
                    <ThemeToggleButton
                      sx={{ mx: 2, my: 1, ml: 0, borderRadius: '25px !important', px: 2 }}
                      value={showQuestionTypes.timeOfDay}
                      // size="small"
                    >
                      Time of day
                    </ThemeToggleButton>
                    <ThemeToggleButton sx={{ mx: 2, my: 1, borderRadius: '25px !important' }} value={showQuestionTypes.duration}>
                      Duration
                    </ThemeToggleButton>
                    <ThemeToggleButton sx={{ mx: 2, my: 1, borderRadius: '25px !important' }} value={showQuestionTypes.element}>
                      Element
                    </ThemeToggleButton>
                    <ThemeToggleButton
                      sx={{
                        mx: 2,
                        my: 1,
                        mr: 0,
                        borderRadius: '25px !important',
                      }}
                      value={showQuestionTypes.dateTime}
                    >
                      Date/Time
                    </ThemeToggleButton>
                  </ToggleButtonGroup>
                )}
              />
              {errors?.triggerType && errors?.triggerType?.message && (
                <Typography variant="caption" color="error">
                  {errors?.triggerType?.message}
                </Typography>
              )}
              {renderHelperComponents()}
            </Grid>
          </Grid>
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={isAdding} type="submit" variant="contained">
              Save Changes
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        rows={reminderRows}
        searchLable="Search Reminders"
        onRowStatusChange={onRowStatusChange}
        onEditClick={onEditClick}
        nestedRowKey={Types.nestedRowTypes.REMINDERS}
        // handleSelectionClick={handleSelectionClick}
        filterKeys={['name']}
        customToolbar={Types.toolbarTypes.AREAS}
        statusUpdateUrl="reminders/update/status"
        maxHeight={453}
        toolbarProps={toolbarProps}
        hideCompactViewButton
        onRowDelete={onDelete}
        hideNestedFunc={hideNestedFunc}
        defaultRowsPerPage={10}
      />
      <DevTool control={control} />
    </Box>
  );
};

export default RemindersPage;
RemindersPage.propTypes = {
  projectID: PropTypes.string,
  customerID: PropTypes.string,
};
