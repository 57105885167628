import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, DialogActions, Grid } from '@mui/material';
import { get, has, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../components/ModalComponent';
import StudiesComponent from '../../../components/StudiesComponent';
import { ThemeButton } from '../../../components/button';
import CheckboxGroup from '../../../components/checkbox/CheckboxGroup';
import GroupCheckbox from '../../../components/formComponents/FormCheckbox';
import FormInput from '../../../components/formComponents/FormInput';
import FormRadioGroup from '../../../components/formComponents/formRadioGroup';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import StatusIndicator from '../../../components/status';
import DataTable from '../../../components/table';
import { Types } from '../../../types';
import { addRoleSchema, editRoleSchema } from '../../../utils/schema';
import { addRole, getRoles, updateRole } from './rolesSlice';
import axiosInstance from '../../../utils/axiosInstance';

const RolesPage = ({ projectID, customerID }) => {
  const rolePositions = useMemo(() => ['Area Manager', 'Manager', 'Supervisor/Team Leader', 'Colleague'], []);
  const disaptch = useDispatch();
  useEffect(() => {
    disaptch(getRoles({ projectID }));
  }, [disaptch, projectID]);
  const { roles, isLoading } = useSelector((state) => state?.roles);

  const headCells = [
    {
      id: 'name',
      numeric: false,
      // disablePadding: true,
      label: 'Role',
      titleCase: true,
      // align: 'left',
    },
    {
      id: 'position',
      numeric: false,
      // disablePadding: true,
      label: 'Position ',
      // titleCase: true,
      // align: 'left',
    },
    {
      id: 'roleGroup',
      label: 'Role Group',
      align: 'left',
      // minWidth: 200,
      titleCase: true,
    },
    {
      id: 'studiesLength',
      disablePadding: false,
      minWidth: '360px',
      // maxWidth: '360px',
      label: 'Studies',
      align: 'center',
      renderElement: (cell) => <StudiesComponent pl={1} minWidth="unset" studyTypes={get(cell, 'projectSetting.studyTypes')} />,
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: '200px',
      renderElement: (cell) => has(cell, 'projectSetting.status') && <StatusIndicator cell={cell?.projectSetting} />,
    },
  ];

  const regularRoles = useMemo(
    () =>
      !isEmpty(roles) &&
      !!roles?.length &&
      roles.reduce(
        (acc, area) => {
          const areaObject = {
            ...area,
            status: get(area, 'projectSetting.status'),
            roleGroup: get(area, 'projectSetting.roleGroup'),
            studiesLength: get(area, 'projectSetting.studyTypes') && area?.projectSetting?.studyTypes?.length,
            position: get(area, 'projectSetting.position') && (rolePositions[get(area, 'projectSetting.position') - 1] || ''),
          };
          if (area?.projectSetting?.status === 'active') {
            acc.active.push(areaObject);
          } else if (area?.projectSetting?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(areaObject);
          }
          acc.default.push(areaObject);
          return acc;
        },
        { active: [], archive: [], default: [] }
      ),
    [rolePositions, roles]
  );
  const [roleRows, setRoleRows] = useState([]);
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [isModalopen, setisModalopen] = useState(false);
  const [Status, setStatus] = useState(ACTIVE);
  const [rowToEdit, setRowToEdit] = useState('');
  useEffect(() => {
    if (regularRoles) {
      if (Status === DEFAULT) {
        setRoleRows(regularRoles?.default);
      } else if (Status === ARCHIVED) {
        setRoleRows(regularRoles?.archive);
      } else if (Status === ACTIVE) {
        setRoleRows(regularRoles?.active);
      }
    } else {
      setRoleRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRoles]);
  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setRoleRows(regularRoles.default);
      } else if (value === ACTIVE) {
        setRoleRows(regularRoles.active);
      } else if (value === ARCHIVED) {
        setRoleRows(regularRoles.archive);
      }
    }
  };
  const form = useForm({
    defaultValues: {
      name: '',
      position: null,

      // optional fields
      studyTypes: [],
    },
    resolver: rowToEdit ? yupResolver(editRoleSchema) : yupResolver(addRoleSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control, clearErrors } = form;
  const { errors } = formState;
  const positionOptions = [
    { label: 'Area Manager', value: 1 },
    { label: 'Manager', value: 2 },
    { label: 'Supervisor/Team Leader', value: 3 },
    { label: 'Colleague', value: 4 },
  ];
  const dispatch = useDispatch();
  const openModal = () => setisModalopen(true);
  const closeModal = () => {
    clearErrors();
    setisModalopen((prevState) => !prevState);
    reset();
    // reset({ permission: '' });
    // setShowPassword(false);
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };
  const getPayload = (selected, status, id) => ({ roleIDs: selected, status, projectID: id });
  const fetchData = () => {
    dispatch(getRoles({ projectID }));
  };
  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search Roles',
    openModal,
    addButtonName: 'Create Role',
    getPayload,
    fetchData,
  };
  const onEditClick = async (event, row) => {
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      const roleToEdit = roles.find((area) => area?._id === row?._id);
      setValue('studyTypes', get(roleToEdit, 'projectSetting.studyTypes', []));
      setValue('position', get(roleToEdit, 'projectSetting.position'));
      setValue('name', get(roleToEdit, 'name', ''));
      openModal();
    } else {
      console.warn('No Row Id found');
    }
  };
  const onSubmit = (values) => {
    if (customerID && projectID) {
      if (rowToEdit) {
        dispatch(
          updateRole({
            studyTypes: get(values, 'studyTypes', []),
            position: get(values, 'position', null),
            customerID,
            projectID,
            _id: rowToEdit,
          })
        )
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Field User Added Successfully'),
                })
              );
              dispatch(getRoles({ projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      } else {
        dispatch(addRole({ name: get(values, 'name', ''), position: get(values, 'position', null), customerID, projectID }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Role Added Successfully'),
                })
              );
              dispatch(getRoles({ projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'roles/update/status',
          method: 'PATCH',
          data: { roleIDs: [row?._id], status, projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Role`,
            })
          );
          dispatch(getRoles({ projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id && projectID) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `roles/delete/${row?._id}`,
          method: 'DELETE',
          data: { projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getRoles({ projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const studyAppConfig = { label: 'label', value: 'value' };
  const studyAppOptions = [
    { label: 'Efficiency Study', value: 1, color: '#f59d0f' },
    { label: 'Role Study', value: 2, color: '#784fc2' },
  ];
  return (
    <Box>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '600px !important',
            maxHeight: '700px',
          },
        }}
        title={rowToEdit ? 'Edit Role' : 'Create Role'}
        // title="Add Area"
        open={isModalopen}
        onClose={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            <Grid columnSpacing={3} rowSpacing={2} container justifyContent="space-between">
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <FormInput
                  label="Name"
                  placeholder="Enter a role (e.g Shop assistant)"
                  disabled={rowToEdit}
                  InputProps={{
                    ...register('name'),
                  }}
                  error={!!errors?.name}
                  helperText={errors?.name && errors?.name?.message}
                />
                {rowToEdit && (
                  <CheckboxGroup
                    control={control}
                    label="Study Types"
                    name="studyTypes"
                    options={studyAppOptions}
                    config={studyAppConfig}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                    '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                    '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                    '& .MuiFormLabel-root .Mui-error ': { color: '#212B36 !important', fontWeight: '600' },
                    width: '80%',
                  }}
                >
                  <GroupCheckbox
                    error={!!errors?.position}
                    helperText={errors?.position && errors?.position?.message}
                    label="Select Position"
                  >
                    <FormRadioGroup fontSize={18} options={positionOptions} control={control} name="position" />
                  </GroupCheckbox>
                </Box>
                {/* <GroupCheckbox label="User Permissions">
              <FormRadioGroup options={options} control={control} name="userPermission" />
            </GroupCheckbox> */}
              </Grid>
            </Grid>
          </Box>
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={isLoading} type="submit" variant="contained">
              {/* {rowToEdit ? 'Save Changes' : 'Create industry'} */}
              Save Changes
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        rows={roleRows}
        searchLable="Search Areas"
        onRowStatusChange={onRowStatusChange}
        onEditClick={onEditClick}
        // handleSelectionClick={handleSelectionClick}
        filterKeys={['name', 'position']}
        customToolbar={Types.toolbarTypes.ROLES}
        statusUpdateUrl="roles/update/status"
        defaultRowsPerPage={10}
        maxHeight={453}
        toolbarProps={toolbarProps}
        hideCompactViewButton
        onRowDelete={onDelete}
      />
      <DevTool control={control} />
    </Box>
  );
};

export default RolesPage;
RolesPage.propTypes = {
  projectID: PropTypes.string,
  customerID: PropTypes.string,
};
