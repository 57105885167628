import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { jwtDecode } from 'jwt-decode';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { ThemeButton } from '../../components/button';
import Iconify from '../../components/Iconify';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { downloadNote } from './notesSlice';

const NotesToolbar = (props) => {
  const {
    setFilterName,
    selected,
    handleSelectionClick,
    filterName,
    onFilterName,
    handleClearSelection,
    toolbarProps,
    visibleRows,
  } = props;
  const numSelected = selected?.length;
  const { onAddClick, handleEcElement, isEcValid } = toolbarProps;
  const { addEcLoading } = useSelector((state) => state.studies);
  const { singleProjectData } = useSelector((state) => state.project);
  const { isNotesDownloading } = useSelector((state) => state.notes);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const jwtToken = cookies.get('token');
  const roles = jwtToken && jwtDecode(jwtToken);
  const handleDownloadNotes = async () => {
    const userID = get(roles, '_id') || get(roles, 'user._id');
    const role = get(roles, 'role');
    const payload = {
      projectID: singleProjectData?._id,
      customerID: singleProjectData?.customerID,
      projectName: singleProjectData?.name,
      customerName: singleProjectData?.customerName,
    };
    if (role && role === 3) {
      payload.userID = userID;
    }
    dispatch(downloadNote(payload))
      .then((response) => {
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message'),
            })
          );
        } else if (get(response, 'payload.message')) {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      })
      .catch((error) => {
        console.log('error==> ', error);
      });
  };
  return (
    <Toolbar
      sx={{
        minHeight: 76,
        py: 2,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: 'flex',
        justifyContent: 'space-between',
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
        }),
      }}
    >
      {/* {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : null} */}

      {numSelected > 0 ? (
        <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
          <Typography color="inherit" variant="subtitle1" component="div">
            {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
          </Typography>
          <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
        </Stack>
      ) : null}

      <Grid p={1} container justifyContent="space-between">
        <Grid item lg={3} md={4.5} xs={12}>
          <FormControl fullWidth>
            <OutlinedInput
              // size='small'
              sx={{
                fontSize: '0.86rem',
                // mt: '5px',
                // mb: '16px',
                borderRadius: 25,
                lineHeight: '1.9375em',
                '.MuiInputBase-input': {
                  height: '0.660em',
                  py: '15px',
                },
              }}
              fullWidth
              // size='small'
              value={filterName}
              onChange={onFilterName}
              placeholder={get(toolbarProps, 'searchLable') || 'Search Notes'}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid display="flex" justifyContent="flex-end" item xs={12} md={6} xl={6} lg={6}>
          <ThemeButton loading={isNotesDownloading} onClick={handleDownloadNotes} color="primary">
            <FileDownloadOutlinedIcon />
          </ThemeButton>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

NotesToolbar.propTypes = {
  selected: PropTypes.array,
  visibleRows: PropTypes.array,
  handleSelectionClick: PropTypes.func,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleClearSelection: PropTypes.func,
  setFilterName: PropTypes.func,
  toolbarProps: PropTypes.object,
};

export default NotesToolbar;
