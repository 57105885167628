import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Types } from '../../types';

const StatusIndicator = ({ cell }) => {
  if (cell) {
    return (
      <Typography color={cell?.status === Types?.ARCHIVED ? 'error.main' : 'success.main'} variant="overline">
        {cell?.status} {cell?.isProjectExpired && ' / EXPIRED'}
      </Typography>
    );
  }
  return null;
};

StatusIndicator.propTypes = {
  cell: PropTypes.object,
};

export default StatusIndicator;
