import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { alpha } from '@mui/material/styles';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Iconify from '../../../components/Iconify';
import { ActionButton, ThemeButton } from '../../../components/button';
import StatusButtons from '../../../components/buttonGroup';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import { Types } from '../../../types';
import axiosInstance from '../../../utils/axiosInstance';
import { downloadArea, getAreas } from './areaSlice';
import ConfirmDialog from '../../../components/ConfirmDialog';

const AreasToolbar = (props) => {
  const {
    selected,
    filterName,
    onFilterName,
    searchLable,
    toolbarProps,
    handleClearSelection,
    setPage,
    handleOpenBackdrop,
    handleCloseBackdrop,
    statusUpdateUrl,
  } = props;
  const numSelected = selected?.length;
  const { onCancel, onSave, placeholder, addButtonName, getPayload, fetchData } = toolbarProps;
  const { singleProjectData } = useSelector((state) => state.project);
  const { isAreaDownloading } = useSelector((state) => state.areas);
  const handleStatusChange = (event) => {
    toolbarProps?.onStatusChange(event);
    setPage(0);
  };
  const params = useParams();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('active');
  const handleStatusButtonClick = (selectedIds, status) => {
    setCurrentStatus(() => status);
    setIsModalOpen(() => true);
  };
  const { id } = params;
  const handleSelectionClick = async (selectedIds, status) => {
    setIsModalOpen(false);
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (statusUpdateUrl && selectedIds && selectedIds?.length > 0) {
      const initialPayload = { areaIDs: selected, status, projectID: id };
      handleOpenBackdrop();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: statusUpdateUrl,
          method: 'PATCH',
          data: getPayload ? getPayload(selected, status, id) : initialPayload,
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
              // snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Area`,
            })
          );
          handleClearSelection();
          setIsModalOpen(false);
          if (fetchData) {
            fetchData();
          }
          // dispatch(getAreas({ projectID: id }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        handleCloseBackdrop();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const handleDeleteElements = async () => {
    if (selected?.length > 0) {
      handleOpenBackdrop();
      setIsModalOpen(false);
      try {
        const response = await axiosInstance({
          url: get(toolbarProps, 'isRemindersPage') ? `reminders/delete/${selected[0]}` : `areas/delete/${selected[0]}`,
          method: 'DELETE',
          data: get(toolbarProps, 'isRemindersPage')
            ? { projectID: id, reminderIDs: selected }
            : { projectID: id, areaIDs: selected },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          handleClearSelection();
          fetchData();
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        handleCloseBackdrop();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const handleDownloadArea = async () => {
    dispatch(
      downloadArea({
        projectID: singleProjectData?._id,
        customerID: singleProjectData?.customerID,
        projectName: singleProjectData?.name,
        customerName: singleProjectData?.customerName,
      })
    )
      .then((response) => {
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message'),
            })
          );
        } else if (get(response, 'payload.message')) {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      })
      .catch((error) => {
        console.log('error==> ', error);
      });
  };
  return (
    <>
      <ConfirmDialog
        title={currentStatus === 'delete' ? 'Delete' : 'Change Status'}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        selectedField={selected}
        onConfirm={() => (currentStatus === 'delete' ? handleDeleteElements() : handleSelectionClick(selected, currentStatus))}
        confirmText={currentStatus === 'delete' ? 'Delete' : 'Change Status'}
      >
        <Typography variant="body1">
          Are you sure you want to change the status to <b>{currentStatus}</b> ?
        </Typography>
      </ConfirmDialog>
      <Toolbar
        sx={{
          minHeight: 76,
          py: 0,
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: 'flex',
          justifyContent: 'space-between',
          ...(numSelected > 0 && {
            bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
            <Typography color="inherit" variant="subtitle1" component="div">
              {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
            </Typography>
            <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
          </Stack>
        ) : null}
        {numSelected > 0 ? (
          <Stack gap direction="row" whiteSpace="nowrap">
            {toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ARCHIVED ? (
              <ActionButton
                type="delete"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                variant="outlined"
                color="error"
                onClick={() =>
                  handleSelectionClick &&
                  handleStatusButtonClick &&
                  handleStatusButtonClick(selected, 'delete', handleClearSelection)
                }
              >
                {`Remove ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
            {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ARCHIVED ? (
              <ActionButton
                type="activate"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                variant="outlined"
                onClick={() =>
                  handleSelectionClick &&
                  handleStatusButtonClick &&
                  handleStatusButtonClick(selected, 'active', handleClearSelection)
                }
              >
                {`Activate ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
            {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ACTIVE ? (
              <ActionButton
                type="archive"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                variant="outlined"
                color="error"
                onClick={() =>
                  handleSelectionClick &&
                  handleStatusButtonClick &&
                  handleStatusButtonClick(selected, 'archive', handleClearSelection)
                }
              >
                {`Archive ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
          </Stack>
        ) : (
          <Grid p={0} container alignItems="center" justifyContent="space-between" spacing>
            <Grid item lg={4} xl={3} md={4} xs={12}>
              <FormControl fullWidth>
                <OutlinedInput
                  sx={{
                    fontSize: '0.86rem',
                    borderRadius: 25,
                    lineHeight: '1.9375em',
                    '.MuiInputBase-input': {
                      height: '0.860em',
                      py: '15px',
                    },
                  }}
                  fullWidth
                  value={filterName}
                  onChange={onFilterName}
                  placeholder={searchLable || placeholder || 'Search customer name...'}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid justifyContent={{ lg: 'flex-start', xl: 'flex-end' }} item xs={12} md={6} xl={6} lg={6}>
              <Stack
                gap={3}
                width="100%"
                sx={{ justifyContent: { lg: 'flex-end' }, alignItems: 'center' }}
                direction="row"
                alignItems="center"
              >
                <ThemeButton loading={isAreaDownloading} onClick={handleDownloadArea} color="primary">
                  <FileDownloadOutlinedIcon />
                </ThemeButton>
                <ThemeButton
                  onClick={toolbarProps?.openModal && toolbarProps?.openModal}
                  size="small"
                  startIcon={<AddCircleOutlineIcon />}
                  variant="outlined"
                >
                  {addButtonName || 'Create Area'}
                </ThemeButton>
                {/* <Typography>Status</Typography> */}
                <StatusButtons
                  status={toolbarProps?.status}
                  onStatusChange={handleStatusChange}
                  fullWidth
                  size="small"
                  // sx={{ width: '80%', mt: '5px', mb: '16px' }}
                  sx={{ width: '60%' }}
                />
              </Stack>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </>
  );
};

AreasToolbar.propTypes = {
  selected: PropTypes.array,
  // handleSelectionClick: PropTypes.func,
  filterName: PropTypes.string,
  statusUpdateUrl: PropTypes.string,
  onFilterName: PropTypes.func,
  setPage: PropTypes.func,
  handleClearSelection: PropTypes.func,
  handleOpenBackdrop: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
  searchLable: PropTypes.string,
  toolbarProps: PropTypes.object,
};

export default AreasToolbar;
