import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHoursMapping } from './hoursMappingSlice';
import { Types } from '../../types';
import DataTable from '../../components/table';

const HoursMapping = () => {
  const dispatch = useDispatch();
  const { hoursMappings, isLoading } = useSelector((state) => state.hoursMapping);
  const [hoursMappingRows, setHoursMappingRows] = useState([]);

  useEffect(() => {
    dispatch(getHoursMapping());
  }, [dispatch]);
  useEffect(() => {
    if (hoursMappings?.length) {
      setHoursMappingRows(hoursMappings);
    }
  }, [hoursMappings]);

  const headCells = [
    {
      id: 'hour',
      label: 'Hour',
      align: 'left',
      minWidth: 200,
      // titleCase: true,
    },
    {
      id: 'hourLabel',
      label: 'Hour Label',
      align: 'left',
      minWidth: 200,
      // titleCase: true,
    },
    {
      id: 'timeslotLabel',
      label: 'Time Slot Label',
      align: 'left',
      minWidth: 200,
      titleCase: true,
    },
  ];
  const onEditClick = (event, row, nestedCollapsedRows, setNestedCollapsedRows) => {
    if (nestedCollapsedRows.indexOf(row?._id) !== -1) {
      setNestedCollapsedRows(nestedCollapsedRows.filter((collapsedRow) => collapsedRow !== row?._id));
    } else {
      setNestedCollapsedRows((prevRows) => [row?._id]);
    }
  };
  return (
    <Container maxWidth="xxl" className="app-container customer">
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
        <Typography variant="h4">Hours Mapping</Typography>
      </Box>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        onEditClick={onEditClick}
        defaultRowsPerPage={10}
        maxHeight={653}
        filterKeys={['hourLabel', 'hour', 'timeslotLabel']}
        customToolbar={Types.toolbarTypes.HOURS_MAPPING}
        nestedRowKey={Types.nestedRowTypes.HOURS_MAPPING}
        disableNestedRowClick
        hideNestedArrow
        // disableCheckbox
        // toolbarProps={toolbarProps}
        // handleSelectionClick={handleSelectionClick}
        rows={hoursMappingRows}
        stickyHeader
      />
    </Container>
  );
};

export default HoursMapping;
