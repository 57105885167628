import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../../utils/axiosInstance';
// import { projects } from './data';

const initialState = {
  isLoading: false,
  addAreaLoading: false,
  areas: [],
  isAreaDownloading: false,
};
export const getAreas = createAsyncThunk('areas/getAreas', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'areas/get',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addArea = createAsyncThunk('areas/addArea', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'areas/add',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const downloadArea = createAsyncThunk('areas/downloadArea', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'areas/download',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateArea = createAsyncThunk('area/updateArea', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: `areas/update/${data?._id}`,
      method: 'PATCH',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const areasSlice = createSlice({
  name: 'areas',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAreas.pending, (state, action) => {
      state.isLoading = true;
      state.areas = [];
    });
    builder.addCase(getAreas.fulfilled, (state, action) => {
      state.isLoading = false;
      state.areas = action.payload?.data;
    });
    builder.addCase(getAreas.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addArea.pending, (state, action) => {
      state.addAreaLoading = true;
    });
    builder.addCase(addArea.fulfilled, (state, action) => {
      state.addAreaLoading = false;
    });
    builder.addCase(addArea.rejected, (state, action) => {
      state.addAreaLoading = false;
    });
    builder.addCase(updateArea.pending, (state, action) => {
      state.addAreaLoading = true;
    });
    builder.addCase(updateArea.fulfilled, (state, action) => {
      state.addAreaLoading = false;
    });
    builder.addCase(updateArea.rejected, (state, action) => {
      state.addAreaLoading = false;
    });
    builder.addCase(downloadArea.pending, (state, action) => {
      state.isAreaDownloading = true;
    });
    builder.addCase(downloadArea.fulfilled, (state, action) => {
      state.isAreaDownloading = false;
    });
    builder.addCase(downloadArea.rejected, (state, action) => {
      state.isAreaDownloading = false;
    });
  },
});

export default areasSlice.reducer;
