/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { yupResolver } from '@hookform/resolvers/yup';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Card,
  Collapse,
  DialogActions,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { get, has, isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../components/ModalComponent';
import StudiesComponent from '../../../components/StudiesComponent';
import { ThemeButton } from '../../../components/button';
import CheckboxGroup from '../../../components/checkbox/CheckboxGroup';
import GroupCheckbox from '../../../components/formComponents/FormCheckbox';
import FormInput from '../../../components/formComponents/FormInput';
import FormRadioGroup from '../../../components/formComponents/formRadioGroup';
import CustomSelect from '../../../components/select';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import StatusIndicator from '../../../components/status';
import ThemeSwitch from '../../../components/switch';
import DataTable from '../../../components/table';
import { Types } from '../../../types';
import { getRatingLabel, getTypeLabel } from '../../../utils';
import axiosInstance from '../../../utils/axiosInstance';
import { addElementInProjectSchema } from '../../../utils/schema';
import FilterModal from '../../admin/element/FilterModal';
import { getCustomers } from '../../customer/customerSlice';
import { SortableElement } from '../../tasksPage/TasksNestedRow';
import { getTasks } from '../../tasksPage/taskSlice';
import { addElement, editElement, getAssignedTasks, getElements } from './elementsSlice';
import { getCategories } from '../category/projectCategoriesSlice';

const ElementsPage = ({ projectID, customerID }) => {
  const dispatch = useDispatch();
  const { elements, isLoading, addElementLoading } = useSelector((state) => state.projectElements);
  const { customers } = useSelector((state) => state.customer);
  const { projectCategories: categories } = useSelector((state) => state.projectCategories);
  const { tasks } = useSelector((state) => state.tasks);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterBy, setFilterBy] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const tasksOptions = useMemo(
    () => (tasks?.length > 0 ? tasks?.map((taskElement) => ({ label: taskElement?.name, value: taskElement?._id })) : []),
    [tasks]
  );
  const tempID = useMemo(() => 'tempID', []);
  useEffect(() => {
    if (projectID) {
      dispatch(getTasks({ projectID }));
      dispatch(getElements({ projectID, defaultEntry: false }));
    }
    // dispatch(getCategories({ defaultEntry: true }));
    dispatch(getCategories({ projectID, defaultEntry: false }));
    dispatch(getCustomers());
  }, [dispatch, projectID]);

  const studyOptions = useMemo(
    () => [
      { label: 'Efficiency Study', value: 1 },
      { label: 'Role Study', value: 2 },
      { label: 'Activity Study', value: 3 },
      // { label: 'Predetermined Study', value: 4 },
    ],
    []
  );
  const typeOptions = useMemo(
    () => [
      { label: 'Fixed', value: 1 },
      { label: 'Variable', value: 2 },
    ],
    []
  );
  const ratingOptions = useMemo(
    () => [
      {
        label: 'Not Rated',
        description: 'No rating screen in app, SMV not shown in CMS or in any values. Only in data summary export',
        value: 1,
      },
      { label: '100', description: 'No rating screen in app, element defaults to 100 rating', value: 2 },
      { label: 'Field User Input', description: 'Show rating screen in the app for the element', value: 3 },
    ],
    []
  );
  const statusOptions = useMemo(
    () => [
      { label: 'Active', value: Types.ACTIVE },
      { label: 'Archive', value: Types.ARCHIVED },
    ],
    []
  );

  const regularElements = useMemo(
    () =>
      !isEmpty(elements) &&
      elements.reduce(
        (acc, element) => {
          const fullElement = {
            ...element,
            name: get(element, 'name')?.toLowerCase(),
            type: get(element, 'projectSetting.type'),
            count: get(element, 'projectSetting.count'),
            categoryID: get(element, 'projectSetting.categoryID'),
            customerID: get(element, 'projectSetting.customerID'),
            studyTypes: get(element, 'projectSetting.studyTypes', []),
            status: get(element, 'projectSetting.status'),
            typeLabel: getTypeLabel(get(element, 'projectSetting.type')),
            categoryClient: get(element, 'projectSetting.categoryClient'),
            elementClient: get(element, 'projectSetting.elementClient'),
            relaxationAllowancePercent:
              get(element, 'projectSetting.relaxationAllowance') && `${element.projectSetting.relaxationAllowance} %`,
            contingencyAllowancePercent:
              get(element, 'projectSetting.contingencyAllowance') && `${element.projectSetting.contingencyAllowance} %`,
            ra: get(element, 'projectSetting.relaxationAllowance', ''),
            ca: get(element, 'projectSetting.contingencyAllowance', ''),
            category: categories?.length
              ? categories?.find((category) => category?._id === get(element, 'projectSetting.categoryID'))?.name
              : null,
            ratingLabel: getRatingLabel(get(element, 'projectSetting.rating')),
            rating: get(element, 'projectSetting.rating'),
          };
          if (element?.name) {
            const descriptionOption = { label: element?.name, value: element?.name?.toLowerCase() };
            acc.descriptionOptions.push(descriptionOption);
          }

          if (element?.projectSetting?.status === 'active') {
            acc.active.push(fullElement);
          } else if (element?.projectSetting?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(fullElement);
          }
          acc.default.push(fullElement);
          return acc;
        },
        { active: [], archive: [], default: [], descriptionOptions: [] }
      ),
    [categories, elements]
  );
  const [elementsRows, setElementsRows] = useState([]);
  const [originalTasks, setOriginalTasks] = useState([]);
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [isModalopen, setisModalopen] = useState(false);
  const [collapsedRow, setCollapsedRow] = useState('');
  const [Status, setStatus] = useState(ACTIVE);
  const [rowToEdit, setRowToEdit] = useState('');
  const [filterConfig, setFilterConfig] = useState({
    filterBy: '',
    filterColumns: [],
    sortOrder: '',
    sortBy: '',
  });
  const studyTypeConfig = { label: 'label', value: 'value' };
  const studyTypeOptions = [
    { label: 'Efficiency Study', value: 1, color: '#f59d0f' },
    { label: 'Role Study', value: 2, color: '#784fc2' },
    { label: 'Activity Study', value: 3, color: '#0ba0ef' },
  ];
  useEffect(() => {
    if (regularElements) {
      if (Status === DEFAULT) {
        setElementsRows(regularElements?.default);
      } else if (Status === ARCHIVED) {
        setElementsRows(regularElements?.archive);
      } else if (Status === ACTIVE) {
        setElementsRows(regularElements?.active);
      }
    } else {
      setElementsRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularElements]);
  const customerOptions = useMemo(() => {
    if (customers?.length) {
      return customers?.map((customer) => ({ label: customer?.name, value: customer?._id }));
    }
    return [];
  }, [customers]);
  const categoryOptions = useMemo(
    () =>
      categories && categories?.length > 0
        ? categories
            .filter((category) => has(category, 'projectSetting.status') && category.projectSetting.status === 'active')
            .map((category) => ({ label: category.name, value: category._id }))
        : [],
    [categories]
  );
  useEffect(() => {
    switch (filterBy) {
      case 'name':
        setFilterOptions(regularElements.descriptionOptions);
        break;
      case 'customerID':
        setFilterOptions(customerOptions);
        break;
      case 'categoryID':
        setFilterOptions(categoryOptions);
        break;
      case 'studyTypes':
        setFilterOptions(studyOptions);
        break;
      case 'rating':
        setFilterOptions(ratingOptions);
        break;
      case 'type':
        setFilterOptions(typeOptions);
        break;
      case 'status':
        setFilterOptions(statusOptions);
        break;

      default:
        setFilterOptions([]);
        break;
    }
  }, [
    categoryOptions,
    customerOptions,
    filterBy,
    ratingOptions,
    regularElements.descriptionOptions,
    statusOptions,
    studyOptions,
    typeOptions,
  ]);
  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setElementsRows(regularElements.default);
      } else if (value === ACTIVE) {
        setElementsRows(regularElements.active);
      } else if (value === ARCHIVED) {
        setElementsRows(regularElements.archive);
      }
    }
  };
  const openFilter = (key, orderBy) => {
    setIsFilterOpen(true);
    setFilterBy(key);
    if (orderBy) {
      setSortBy(orderBy);
    }
  };
  const closeFilter = () => setIsFilterOpen(false);

  const form = useForm({
    defaultValues: {
      // defaultEntry: true,
      name: '',
      unitOfMeasure: '',
      studyTypes: [],
      tasks: [],
      rating: 3,
      type: 2,
      count: false,
      contingencyAllowance: null,
      relaxationAllowance: null,
      categoryID: '',
    },
    resolver: yupResolver(addElementInProjectSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'tasks', // unique name for your Field Array
  });
  const studyTypesValues = watch('studyTypes');
  const headCells = [
    {
      id: 'name',
      label: 'Description',
      align: 'left',
      // minWidth: 200,
      titleCase: true,
      onClick: () => openFilter('name', 'name'),
    },
    {
      id: 'elementClient',
      label: 'Element Client',
      align: 'left',
      // minWidth: 200,
      titleCase: true,
    },
    // {
    //   id: 'name',
    //   label: 'Customer',
    //   align: 'left',
    // minWidth: 200,
    //   titleCase: true,
    // },
    {
      id: 'typeLabel',
      label: 'Type',
      align: 'left',
      // minWidth: 200,
      titleCase: true,
      onClick: () => openFilter('type', 'typeLabel'),
    },
    {
      id: 'count',
      label: 'Count',
      align: 'left',
      // minWidth: 200,
      renderElement: (cell) =>
        !isUndefined(cell, 'projectSetting.count') && get(cell, 'projectSetting.count') ? (
          <CheckCircleOutlineOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="success" />
        ) : (
          <CancelOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="error" />
        ),
      // titleCase: true,
    },
    {
      // id: '',
      label: 'Studies ',
      align: 'center',
      // minWidth: '360px',
      maxWidth: '240px',
      renderElement: (cell) => <StudiesComponent studyTypes={get(cell, 'projectSetting.studyTypes')} />,
      titleCase: true,
      onClick: () => openFilter('studyTypes', 'studyTypes'),
    },
    {
      id: 'category',
      label: 'Category ',
      align: 'left',
      // minWidth: 200,
      // titleCase: true,
      onClick: () => openFilter('categoryID', 'category'),
    },
    {
      id: 'categoryClient',
      label: 'Category Client',
      align: 'left',
      // minWidth: 200,
      titleCase: true,
    },
    {
      id: 'ra',
      label: 'R.A ',
      align: 'left',
      renderElement: (cell) => <Typography>{`${get(cell, 'ra', 0)} %`}</Typography>,
      // minWidth: 200,
    },
    {
      id: 'ca',
      label: 'C.A ',
      align: 'left',
      renderElement: (cell) => <Typography>{`${get(cell, 'ca', 0)} %`}</Typography>,
      // minWidth: 200,
    },
    {
      id: 'ratingLabel',
      label: 'Rating ',
      align: 'left',
      // minWidth: 200,
      titleCase: true,
      onClick: () => openFilter('rating', 'ratingLabel'),
    },
    // {
    //   id: 'analysisLabel',
    //   label: 'Customer  ',
    //   align: 'left',
    // minWidth: 200,
    //   titleCase: true,
    // },
    {
      id: 'status',
      label: 'Status',
      renderElement: (cell) => <StatusIndicator cell={cell} />,
      // width: '15%',
    },
  ];
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const openModal = () => setisModalopen(true);
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    reset();
    // reset({ permission: '' });
    // setShowPassword(false);
    setValue('tasks', []);
    setOriginalTasks([]);
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentElementname, setCurrentElementName] = useState('');
  const open = Boolean(anchorEl);
  const addTaskClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelectTask = (event, value) => {
    const selectedTask = tasks.find((task) => task?._id === value);
    if (selectedTask) {
      const newTask = {
        taskID: selectedTask?._id,
        taskName: selectedTask?.name,
        elements:
          rowToEdit && selectedTask?.projectSetting?.elements.includes(rowToEdit)
            ? get(selectedTask, 'projectSetting.elements', [])
            : [tempID, ...get(selectedTask, 'projectSetting.elements', [])],
      };
      append(newTask);
    }
    handleClose();
  };

  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'elements/update/status',
          method: 'PATCH',
          data: { elementIDs: [row?._id], status, projectID, defaultEntry: false },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Elements`,
            })
          );
          dispatch(getElements({ projectID, defaultEntry: false }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id && projectID) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `elements/delete/${row?._id}`,
          method: 'DELETE',
          data: { projectID, defaultEntry: false },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getElements({ projectID, defaultEntry: false }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const getPayload = (selected, status, id) => ({ elementIDs: selected, status, projectID: id, defaultEntry: false });
  const fetchData = () => {
    dispatch(getElements({ projectID, defaultEntry: false }));
  };
  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search Elements',
    openModal,
    addButtonName: 'Create Element',
    getPayload,
    fetchData,
    categoryOptions,
    tasksOptions,
  };
  const onSubmit = (values) => {
    if (values) {
      const { tasks: taskValues, ...rest } = values;
      const payload = { ...rest };
      if ((values.studyTypes.includes(2) || values.studyTypes.includes(3)) && taskValues && taskValues.length > 0) {
        const newTaskValues = taskValues.map((task) => ({
          ...task,
          elements: task.elements?.length > 0 ? task.elements.map((el) => (el === tempID ? values?.name : el)) : task.elements,
        }));
        payload.tasks = newTaskValues;
      }
      if (get(values, 'rating')) {
        payload.rating = parseInt(values.rating, 10);
      }
      if (get(values, 'type')) {
        payload.type = parseInt(values.type, 10);
      }

      if (customerID && projectID) {
        if (rowToEdit) {
          if (originalTasks && originalTasks?.length > 0) {
            const removedTasks =
              taskValues && taskValues?.length > 0
                ? originalTasks?.filter((original) => !taskValues.some((task) => task?.taskID === original))
                : originalTasks;
            if (removedTasks && removedTasks?.length > 0) {
              payload.removeTasks = removedTasks;
            }
          }

          dispatch(editElement({ ...payload, defaultEntry: false, _id: rowToEdit, customerID, projectID })).then((response) => {
            if (response.payload?.success) {
              closeModal();
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message' || 'User Updated Successfully'),
                })
              );
              dispatch(getElements({ projectID, defaultEntry: false }));
            } else {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          });
        } else {
          dispatch(addElement({ ...payload, defaultEntry: false, customerID, projectID }))
            .then((response) => {
              if (response.payload?.success) {
                dispatch(
                  setSnackbar({
                    snackbarOpen: true,
                    snackbarType: 'success',
                    snackbarMessage: get(response, 'payload.message', 'Element Added Successfully'),
                  })
                );
                dispatch(getElements({ projectID, defaultEntry: false }));
                closeModal();
              } else if (get(response, 'payload.message')) {
                const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
                dispatch(
                  setSnackbar({
                    snackbarOpen: true,
                    snackbarType: 'error',
                    snackbarMessage: errorMessage,
                  })
                );
              }
            })
            .catch((error) => {
              console.log('error==> ', error);
            });
        }
      }
    }
  };
  const onEditClick = async (event, row) => {
    if (row?._id) {
      setRowToEdit(() => row?._id);
      const elementToEdit = elements.find((element) => element?._id === row?._id);
      if (elementToEdit) {
        setValue('name', get(elementToEdit, 'name', ''));
        setCurrentElementName(get(elementToEdit, 'name', ''));
        setValue('categoryID', get(elementToEdit, 'projectSetting.categoryID', ''));
        if (get(elementToEdit, 'projectSetting.unitOfMeasure')) {
          setValue('unitOfMeasure', get(elementToEdit, 'projectSetting.unitOfMeasure', ''));
        }
        if (get(elementToEdit, 'projectSetting.rating')) {
          setValue('rating', get(elementToEdit, 'projectSetting.rating', ''));
        }
        if (get(elementToEdit, 'projectSetting.studyTypes')) {
          setValue('studyTypes', get(elementToEdit, 'projectSetting.studyTypes', ''));
        }
        if (
          get(elementToEdit, 'projectSetting.contingencyAllowance') ||
          get(elementToEdit, 'projectSetting.contingencyAllowance') === 0
        ) {
          setValue('contingencyAllowance', get(elementToEdit, 'projectSetting.contingencyAllowance', ''));
        }
        if (
          get(elementToEdit, 'projectSetting.relaxationAllowance') ||
          get(elementToEdit, 'projectSetting.relaxationAllowance') === 0
        ) {
          setValue('relaxationAllowance', get(elementToEdit, 'projectSetting.relaxationAllowance', ''));
        }
        if (get(elementToEdit, 'projectSetting.count')) {
          setValue('count', get(elementToEdit, 'projectSetting.count', ''));
        }
        if (get(elementToEdit, 'projectSetting.type')) {
          setValue('type', get(elementToEdit, 'projectSetting.type', ''));
        }
      }
      dispatch(getAssignedTasks({ projectID, elementID: row?._id })).then((response) => {
        if (get(response, 'payload.data') && response.payload.data?.length > 0) {
          setValue('tasks', get(response, 'payload.data', []));
          setOriginalTasks(get(response, 'payload.data', [])?.map((task) => task?.taskID));
        }
      });
      openModal();
    } else {
      console.warn('No Row Id found');
    }
  };
  const tasksValues = watch('tasks');
  const getTaskElement = (index) => {
    if (get(tasksValues, `${index}.taskID`)) {
      const taskId = get(tasksValues, `${index}.taskID`);
      const selectedTask = tasks.find((task) => task?._id === taskId);
      const filteredElementIds = get(selectedTask, 'projectSetting.elements', []);
      const filteredElements = elements
        .filter((element) => filteredElementIds.includes(element?._id))
        ?.map((el) => ({ label: el?.name, value: el?._id }));
      return filteredElements;
    }
    return [];
  };
  const handleDragEnd = (event, field, index) => {
    const { active, over } = event;
    const currentElements = field?.elements;
    const activeIndex = currentElements?.findIndex((element) => element === active.id);
    const overIndex = currentElements?.findIndex((element) => element === over.id);
    const sortedTempArray = arrayMove(currentElements, activeIndex, overIndex);
    const taskValToUpdate = [...tasksValues];
    taskValToUpdate[index].elements = sortedTempArray;
    setValue('tasks', taskValToUpdate);
  };
  const rowClicked = (element) => {
    if (collapsedRow === element) {
      setCollapsedRow('');
    } else {
      setCollapsedRow(element);
    }
  };
  return (
    <Box>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '600px !important',
            maxHeight: '700px !important',
          },
        }}
        title="Filter"
        open={isFilterOpen}
        onClose={setIsFilterOpen}
      >
        <FilterModal
          setFilterConfig={setFilterConfig}
          filterConfig={filterConfig}
          filterOptions={filterOptions}
          closeFilter={closeFilter}
          filterBy={filterBy}
          sortBy={sortBy}
        />
      </ModalComponent>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '1400px !important',
          },
        }}
        title={rowToEdit ? 'Edit Element' : 'Create Element'}
        open={isModalopen}
        onClose={closeModal}
        DialogActions={
          <DialogActions sx={{ m: 2, px: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={addElementLoading} onClick={handleSubmit(onSubmit)} type="submit" variant="contained">
              {rowToEdit ? 'Save Changes' : 'Create Element'}
            </ThemeButton>
          </DialogActions>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid rowSpacing={1} columnSpacing={3} container>
            <Grid className="left-side" item xs={12} md={6} lg={6}>
              <Grid rowSpacing={1} columnSpacing={1} container>
                <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={12}>
                  <FormInput
                    width="100%"
                    label="Name"
                    placeholder="e.g Greet Customer"
                    disabled={rowToEdit}
                    InputProps={{
                      ...register('name'),
                      onBlur: (event) => setCurrentElementName(event.target.value),
                    }}
                    error={!!errors?.name}
                    helperText={errors?.name && errors?.name?.message}
                  />
                </Grid>
                <Grid className="unit-of-measures" item xs={12} md={6}>
                  <FormInput
                    label="Unit of Measure"
                    placeholder="e.g Per item"
                    // fontSize="0.9375rem"
                    // fontWeight={600}
                    InputProps={{
                      ...register('unitOfMeasure'),
                    }}
                    width="100%"
                    error={!!errors?.unitOfMeasure}
                    helperText={errors?.unitOfMeasure && errors?.unitOfMeasure?.message}
                  />
                </Grid>
                <Grid className="category" item xs={12} md={6}>
                  <label id="category-select" style={{ fontSize: '0.8600rem' }}>
                    Category
                  </label>
                  <CustomSelect
                    width="100%"
                    name="categoryID"
                    // InputProps={{ ...register('customerID') }}
                    control={control}
                    options={categoryOptions}
                    label="Select a category"
                    // value={watch('customerID')}
                    error={!!errors?.categoryID}
                    helperText={errors?.categoryID && errors?.categoryID?.message}
                    // placeholder=Chooseexpiration date"
                  />
                </Grid>
                <Grid className="type" item xs={12} md={4}>
                  <Box
                    sx={{
                      // '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                      '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                      '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                      '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
                      mt: 1,
                    }}
                  >
                    <GroupCheckbox error={!!errors?.type} helperText={errors?.type && errors?.type?.message} label="Type">
                      <FormRadioGroup row fontSize={18} options={typeOptions} control={control} name="type" />
                    </GroupCheckbox>
                  </Box>
                </Grid>
                <Grid mt={1} item xs={12} md={4}>
                  <FormInput
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    type="number"
                    label="Relaxation Allowance"
                    placeholder="e.g 1"
                    // fontSize="0.9375rem"
                    // fontWeight={600}
                    InputProps={{
                      ...register('relaxationAllowance'),
                    }}
                    width="100%"
                    error={!!errors?.relaxationAllowance}
                    helperText={errors?.relaxationAllowance && errors?.relaxationAllowance?.message}
                  />
                </Grid>
                <Grid mt={1} item xs={12} md={4}>
                  <FormInput
                    type="number"
                    label="Contingency Allowance"
                    placeholder="e.g 1"
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    // fontSize="0.9375rem"
                    // fontWeight={600}
                    InputProps={{
                      ...register('contingencyAllowance'),
                    }}
                    width="100%"
                    error={!!errors?.contingencyAllowance}
                    helperText={errors?.contingencyAllowance && errors?.contingencyAllowance?.message}
                  />
                </Grid>
                <Grid className="count" item xs={12} md={12}>
                  <ThemeSwitch
                    description="Relevant only on Role and Activity studies"
                    label="Count"
                    control={control}
                    name="count"
                    width="100%"
                  />
                </Grid>
                <Grid className="study-types" item xs={12} md={12}>
                  <CheckboxGroup
                    row
                    control={control}
                    label="Study Types"
                    name="studyTypes"
                    options={studyTypeOptions}
                    config={studyTypeConfig}
                  />
                </Grid>
                <Grid className="rating" item xs={12} md={12}>
                  <Box
                    sx={{
                      '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                      '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                      '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                      '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
                      width: '80%',
                    }}
                  >
                    <GroupCheckbox
                      error={!!errors?.rating}
                      helperText={errors?.rating && errors?.rating?.message}
                      label="Default Rating"
                    >
                      <FormRadioGroup fontSize={18} options={ratingOptions} control={control} name="rating" />
                    </GroupCheckbox>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="right-side" item xs={12} md={6} lg={6}>
              <Grid rowSpacing={1} columnSpacing={1} container>
                {studyTypesValues && (studyTypesValues.includes(2) || studyTypesValues.includes(3)) && (
                  <Grid item xs={12} lg={12} md={12}>
                    {/* <FormHelperText style={{ fontSize: '0.8600rem', marginBottom: 8, fontWeight: 'unset' }}>Tasks</FormHelperText> */}
                    <Stack mb={2} justifyContent="space-between" direction="row">
                      <Stack width="100%" direction="column">
                        <Typography variant="subtitle2">Tasks</Typography>
                        {get(errors, 'elements.root.message') && (
                          <Typography sx={{ color: 'error.main' }} variant="caption">
                            {get(errors, 'elements.root.message')}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    {fields?.length > 0 ? (
                      <Stack>
                        {fields.map((field, index) => (
                          <Stack key={field?.id} width="100%" direction="column" gap mb={1}>
                            <Stack gap direction="row" width="100%">
                              <Box width="100%">
                                <Card
                                  onClick={() => rowClicked(field?.taskID)}
                                  sx={{
                                    cursor: 'pointer',
                                    p: 2,
                                    bgcolor: '#f5f5f5f0',
                                    '&:hover': {
                                      backgroundColor: '#ebe9e9f0',
                                    },
                                  }}
                                >
                                  <Stack width="100%" alignItems="center" justifyContent="space-between" direction="row">
                                    <Typography fontWeight="bold" variant="caption">
                                      {field?.taskName}
                                    </Typography>
                                    {collapsedRow === field?.taskID ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </Stack>
                                </Card>
                              </Box>
                              <IconButton onClick={() => remove(index)} sx={{ mt: 0 }}>
                                <CancelIcon color="error" />
                              </IconButton>
                            </Stack>

                            <Collapse in={collapsedRow === field?.taskID}>
                              <DndContext
                                modifiers={[restrictToFirstScrollableAncestor, restrictToVerticalAxis]}
                                // autoScrol={false}
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={(event) => handleDragEnd(event, field, index)}
                              >
                                {get(tasksValues, `${index}.elements`) && (
                                  <SortableContext
                                    items={get(tasksValues, `${index}.elements`, [])}
                                    strategy={verticalListSortingStrategy}
                                  >
                                    <Box
                                      py={2}
                                      sx={{ bgcolor: '#f5f5f5f0', overflowY: 'auto', maxHeight: 300, width: 'calc(100% - 50px)' }}
                                    >
                                      {get(tasksValues, `${index}.elements`)?.length > 0 &&
                                        tasksValues[index]?.elements?.map((element) => {
                                          const elementFound = elements.find((value) => value?._id === element);
                                          if (elementFound || element === tempID) {
                                            return (
                                              <SortableElement sx={{ cursor: 'grab !important' }} id={element} key={element}>
                                                <Box
                                                  gap={2}
                                                  p={2}
                                                  my={1}
                                                  width={500}
                                                  height={20}
                                                  alignItems="center"
                                                  display="flex"
                                                >
                                                  <DragIndicatorIcon sx={{ color: '#636c72ed' }} fontSize="small" />
                                                  <Typography
                                                    sx={{ color: element === tempID ? 'primary.main' : 'text.main' }}
                                                    variant="subtitle2"
                                                  >
                                                    {element === tempID
                                                      ? currentElementname || 'Current Element'
                                                      : elements.find((value) => value?._id === element)?.name}
                                                  </Typography>
                                                </Box>
                                              </SortableElement>
                                            );
                                          }
                                          return null;
                                        })}
                                    </Box>
                                  </SortableContext>
                                )}
                              </DndContext>
                            </Collapse>
                          </Stack>
                        ))}
                      </Stack>
                    ) : null}
                    <Stack direction="row" width="100%" justifyContent="flex-end">
                      <ThemeButton onClick={addTaskClick}>Add To Task</ThemeButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        // PaperProps={{
                        //   style: {
                        //     maxHeight: ITEM_HEIGHT * 4.5,
                        //     width: '20ch',
                        //   },
                        // }}
                      >
                        {tasksOptions && tasksOptions?.length > 0 ? (
                          tasksOptions.map((option) => (
                            <MenuItem
                              value={option?.value}
                              key={option?.value}
                              onClick={(event) => handleSelectTask(event, option?.value)}
                              disabled={tasksValues.some((task) => task?.taskID === option?.value)}
                            >
                              {option?.label}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>
                            <i>No task available</i>
                          </MenuItem>
                        )}
                      </Menu>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        rows={elementsRows}
        searchLable="Search Areas"
        onRowStatusChange={onRowStatusChange}
        onEditClick={onEditClick}
        handleRowClick={onEditClick}
        statusUpdateUrl="elements/update/status"
        // handleSelectionClick={handleSelectionClick}
        filterKeys={['name', 'typeLabel']}
        customToolbar={Types.toolbarTypes.ELEMENT}
        defaultRowsPerPage={10}
        maxHeight={453}
        toolbarProps={toolbarProps}
        hideCompactViewButton
        filterColumns={filterConfig?.filterColumns}
        filterBy={filterConfig?.filterBy}
        sortOrder={filterConfig?.sortOrder}
        sortBy={filterConfig?.sortBy}
        onRowDelete={onDelete}
        setElementFilterConfig={setFilterConfig}
      />
    </Box>
  );
};

export default ElementsPage;
ElementsPage.propTypes = {
  projectID: PropTypes.string,
  customerID: PropTypes.string,
};
